import OneSignal from "react-onesignal";
import { Axios } from "../plugins";

const login = async () => {
  try {
    const { id, token, optedIn } = OneSignal.User.PushSubscription;
    id && token && optedIn && OneSignal.login(id, token);
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

const updateExternalId = async () => {
  try {
    const { id } = OneSignal.User.PushSubscription;
    const { data } = await Axios.post("/auth/notify-id", { notifyId: id });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

export { login as LoginOneSignal, updateExternalId as UpdateExternalId };
