import React, { useState } from "react";
import { observer } from "mobx-react";
import { Box, Modal, Button, Typography, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoFull from "../../assets/images/LogoFull.png";
import "../../assets/styles/Modal.scss";
import { Close as CloseIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { useStores } from "../../stores";
import ImageIcon from "../ImageIcon";
import LoginForm from "../forms/Login";
import RegisterForm from "../forms/Register";
import ForgotPasswordForm from "../forms/ForgotPassword";
import {
  Login as LoginService,
  GetMe as GetMeService,
  ForgotPassword as ForgotPasswordService,
  LoginWithFacebook as LoginWithFacebookService,
} from "../../services/User";
import { Login as LoginModel } from "../../models/User";
import ReactGA from "react-ga4";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Authentication = observer((props: Props) => {
  const appName = process.env.REACT_APP_Name;
  const { t } = useTranslation();
  const { UserStore, SnackbarStore } = useStores();
  const [currentState, setCurrentState] = useState(1);

  const bodyAuthenticationForm = () => {
    switch (currentState) {
      case 1:
        return (
          <>
            <Box
              width={"100%"}
              maxWidth={250}
              marginX={"auto"}
              marginBottom={4}
            >
              <img
                src={LogoFull}
                alt={appName}
                className="img-object-contain"
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              textAlign={"center"}
            >
              <Button
                variant="facebook"
                className="button-facebook"
                onClick={handleLoginWithFacebook}
              >
                <Box display={"flex"} marginRight={1}>
                  <ImageIcon iconClassName="image-icon-white facebook" />
                </Box>
                {t("authentication.signInWithFacebook")}
              </Button>
              <Typography
                variant="body1"
                color={StyleVariables["color-neutral"]}
              >
                {t("common.or")}
              </Typography>
              <Button
                variant="contained"
                className="button-facebook"
                onClick={() => setCurrentState(2)}
              >
                {t("authentication.registerWithEmail")}
              </Button>
            </Box>
            <Box
              marginTop={4}
              marginBottom={2}
              textAlign={"center"}
              color={StyleVariables["color-neutral"]}
            >
              <Typography
                variant="link"
                paddingX={1}
                onClick={() => setCurrentState(3)}
              >
                {t("authentication.signIn")}
              </Typography>{" "}
              |{" "}
              <Typography
                variant="link"
                paddingX={1}
                onClick={() => setCurrentState(4)}
              >
                {t("authentication.forgotPassword")}
              </Typography>
            </Box>
          </>
        );
      case 2:
        return (
          <RegisterForm
            onClickLogin={() => setCurrentState(1)}
            onLogIn={onHandleLogin}
          />
        );
      case 3:
        return (
          <LoginForm
            onClickRegister={() => setCurrentState(1)}
            onClickForgotPassword={() => setCurrentState(4)}
            onLogIn={onHandleLogin}
          />
        );
      case 4:
        return (
          <ForgotPasswordForm
            onClickBackToLogin={() => setCurrentState(1)}
            onForgotPassword={handleForgotPassword}
          />
        );
      default:
        break;
    }
  };
  const handleClose = () => {
    props.onClose();
    setCurrentState(1);
  };

  const onHandleLogin = async (payload: LoginModel) => {
    SnackbarStore.onOpenSnackbarProcessing("กำลังเข้าสู่ระบบ");
    const responseLogin = await LoginService({ ...payload });
    if (responseLogin.success) {
      const responseMe = await GetMeService();
      SnackbarStore.onCloseSnackbarProcessing();
      if (responseMe.success) {
        UserStore.onSetIsLoggedIn(true);
        UserStore.onSetUser(responseMe.data || { username: "", email: "" });
        SnackbarStore.onOpenSnackbarSuccess("เข้าสู่ระบบสำเร็จ");
        handleClose();
        ReactGA.event("login", { method: "Email" });
      }
    } else {
      SnackbarStore.onCloseSnackbarProcessing();
      SnackbarStore.onOpenSnackbarError(
        "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
      );
    }
  };

  const handleForgotPassword = async (email: string) => {
    SnackbarStore.onOpenSnackbarProcessing(
      "กำลังส่งลิงก์สำหรับรีเซ็ตรหัสผ่านไปที่อีเมล"
    );
    const response = await ForgotPasswordService(email);
    SnackbarStore.onCloseSnackbarProcessing();
    if (response.success) {
      SnackbarStore.onOpenSnackbarSuccess(
        "ส่งลิงก์สำหรับรีเซ็ตรหัสผ่านไปที่อีเมลเรียบร้อยแล้ว"
      );
      handleClose();
    } else {
      SnackbarStore.onOpenSnackbarError(
        "ส่งลิงก์สำหรับรีเซ็ตรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
      );
    }
  };

  const handleLoginWithFacebook = async () => {
    SnackbarStore.onOpenSnackbarProcessing("กำลังดำเนินการ");
    await LoginWithFacebookService();
    SnackbarStore.onCloseSnackbarProcessing();
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <Fade in={props.isOpen}>
        <Box className="modal modal-sm">
          <Box id="modal-title">
            <Box
              color={StyleVariables["color-neutral"]}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              paddingTop={2}
              paddingX={2}
              className="cursor-pointer"
              onClick={handleClose}
            >
              <CloseIcon />
            </Box>
            <Box className="modal-body">{bodyAuthenticationForm()}</Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
});

export default Authentication;
