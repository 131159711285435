import { Axios } from "../plugins";
import {
  Register as RegisterModel,
  Login as LoginModel,
  ResetPassword as ResetPasswordModel,
  UpdateUser as UpdateUserModel,
} from "../models/User";
import { Paths as PathsConstant } from "../constants/Route";
import OneSignal from "react-onesignal";

const register = async (payload: RegisterModel) => {
  try {
    const { data } = await Axios.post("/users", {
      ...payload,
    });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const login = async (payload: LoginModel) => {
  try {
    const { data }: { data: any } = await Axios.post("/auth/login", {
      ...payload,
    });
    await localStorage?.setItem("accessToken", data.data?.accessToken);
    await localStorage?.setItem("refreshToken", data.data?.refreshToken);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken");
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const getMe = async () => {
  try {
    const { data } = await Axios.get("/auth/me");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken");
    OneSignal.logout();
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const forgotPassword = async (email: string) => {
  try {
    const { data } = await Axios.post("/auth/send-reset-password", { email });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const loginWithFacebook = async () => {
  try {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/login/facebook`;
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const loginWithOneTimeToken = async (token: string) => {
  try {
    const { data } = await Axios.post("/auth/login/otp", { token });
    await localStorage.setItem("accessToken", data.data?.accessToken);
    await localStorage.setItem("refreshToken", data.data?.refreshToken);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const resetPassword = async (payload: ResetPasswordModel) => {
  try {
    const { data } = await Axios.post("/auth/reset-password", { ...payload });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const logout = async () => {
  try {
    const { data } = await Axios.post("/auth/logout");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  } finally {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken");
    OneSignal.logout();
    window.location.href = PathsConstant.home;
  }
};

const uploadFile = async (file: File) => {
  try {
    const form = new FormData();
    form.append("file", file);
    const { data } = await Axios.post("/users/upload", form);
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

const updateProfile = async (payload: UpdateUserModel) => {
  try {
    const { data } = await Axios.patch("/users", { ...payload });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      error: {
        statusCode: error.response?.data?.code,
        message: error.response?.data?.message,
      },
    };
  }
};

export {
  register as Register,
  login as Login,
  getMe as GetMe,
  forgotPassword as ForgotPassword,
  loginWithFacebook as LoginWithFacebook,
  loginWithOneTimeToken as LoginWithOneTimeToken,
  resetPassword as ResetPassword,
  logout as Logout,
  uploadFile as UploadFile,
  updateProfile as UpdateProfile,
};
