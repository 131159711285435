import React from "react";
import { useMetaTags } from "react-metatags-hook";
import { Box, Container, Typography } from "@mui/material";
import GeneralLayout from "../../layouts/General";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import ProfileForm from "../../components/forms/Profile";
import { MetaTags as MetaTagsConstant } from "../../constants/MetaTag";

type Props = {};

const Edit = (props: Props) => {
  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  return (
    <GeneralLayout>
      <Box className="container-content bg-gradient-full">
        <Container>
          <Box paddingY={4} color={StyleVariables["color-white"]}>
            <Typography variant="h4" textAlign={"center"} marginBottom={3}>
              แก้ไขโปรไฟล์
            </Typography>
            <ProfileForm />
          </Box>
        </Container>
      </Box>
    </GeneralLayout>
  );
};

export default Edit;
