import StyleVariables from "../assets/styles/Variable.module.scss";
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    facebook: true;
    "contained-warning": true;
    "outlined-upload": true;
    "contained-delete": true;
    "outlined-cancel": true;
  }
}

const Button: any = {
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: "none !important",
        paddingTop: Number(StyleVariables.spacing),
        paddingBottom: Number(StyleVariables.spacing),
        paddingLeft: Number(StyleVariables.spacing) * 2,
        paddingRight: Number(StyleVariables.spacing) * 2,
        borderRadius: "4px !important",
        minHeight: 40,
        position: "relative",
        "&.Mui-disabled": {
          borderColor: "transparent",
        },
      },
      outlined: {
        background: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-primary"]}`,
        "&:hover": {
          color: StyleVariables["color-primaryDark"],
          border: `1px solid ${StyleVariables["color-primary"]}`,
          background: StyleVariables["color-white"],
        },
      },
      contained: {
        background: StyleVariables["color-primary"],
        color: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-primary"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          borderColor: `1px solid ${StyleVariables["color-primary"]}`,
          background: StyleVariables["color-primaryDark"],
        },
      },
      facebook: {
        background: StyleVariables["color-facebook"],
        color: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-facebook"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          borderColor: `1px solid ${StyleVariables["color-facebook"]}`,
          background: StyleVariables["color-facebook"],
        },
      },
      "contained-warning": {
        background: StyleVariables["color-warning"],
        color: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-warning"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          borderColor: `1px solid ${StyleVariables["color-warning"]}`,
          background: StyleVariables["color-warning"],
        },
      },
      "outlined-upload": {
        background: "transparent",
        border: `1px solid ${StyleVariables["color-white"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          border: `1px solid ${StyleVariables["color-white"]}`,
          background: "transparent",
        },
      },
      "contained-delete": {
        background: StyleVariables["color-error"],
        color: StyleVariables["color-white"],
        border: `1px solid ${StyleVariables["color-error"]}`,
        "&:hover": {
          color: StyleVariables["color-white"],
          borderColor: `1px solid ${StyleVariables["color-error"]}`,
          background: StyleVariables["color-error"],
        },
      },
      "outlined-cancel": {
        background: "transparent",
        border: `1px solid ${StyleVariables["color-neutral"]}`,
        color: StyleVariables["color-neutral"],
        "&:hover": {
          color: StyleVariables["color-neutral"],
          border: `1px solid ${StyleVariables["color-neutral"]}`,
          background: "transparent",
        },
      },
    },
    variants: [
      {
        props: { variant: "facebook" },
      },
      {
        props: { variant: "contained-warning" },
      },
      {
        props: { variant: "outlined-upload" },
      },
      {
        props: { variant: "contained-delete" },
      },
      {
        props: { variant: "outlined-cancel" },
      },
    ],
  },
};

export default Button;
