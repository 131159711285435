import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Autocomplete,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import Typewriter from "typewriter-effect";
import { useDebouncedCallback } from "use-debounce";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import ImageIcon from "../ImageIcon";
import LogoGray from "../../assets/images/LogoGray.png";

type Props = {
  options: any[];
  optionKeyLabel?: string;
  optionKeyImage?: string;
  placeholderAnimateThList: string[];
  placeholderAnimateEnList: string[];
  isShowBackButton?: boolean;
  debounce?: number;
  onClickBackButton?: () => void;
  onClickSearchButton: (value: string) => void;
  onSelected: (value: any) => void;
  onInputChange: (value: string) => void;
};

const SearchWithAnimatePlaceholder = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { i18n } = useTranslation();
  const optionLabelKeyName = props.optionKeyLabel;
  const optionImageKeyName = props.optionKeyImage;
  const [isShowPlaceholder, setIsShowPlaceholder] = useState<boolean>(true);
  const [selected, setSelected] = useState("");
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = useDebouncedCallback((value) => {
    setInputValue(value);
    props.onInputChange(value);
  }, props.debounce || 0);
  const handleSelected = (value: any) => {
    setSelected(value);
    props.onSelected(value);
  };

  return (
    <Box position={"relative"} width={"100%"}>
      {isShowPlaceholder && (!selected || !inputValue) && (
        <Box
          position={"absolute"}
          paddingLeft={props.isShowBackButton ? 7 : 2}
          paddingRight={2}
          paddingY={1}
          width={`calc(100% - ${Number(StyleVariables.spacing) * 14}px)`}
          color={StyleVariables["color-placeholder"]}
          fontWeight={300}
        >
          <Typewriter
            options={{
              strings:
                i18n.language === "th"
                  ? props.placeholderAnimateThList
                  : props.placeholderAnimateEnList,
              autoStart: true,
              loop: true,
              cursor: "",
            }}
          />
        </Box>
      )}
      <Autocomplete
        className="input-search-with-animate-placeholder"
        clearIcon=""
        freeSolo
        disableClearable
        autoHighlight
        options={props.options}
        getOptionLabel={(option) =>
          props.optionKeyLabel ? option[props.optionKeyLabel] : option
        }
        onChange={(event, value) => handleSelected(value)}
        onInputChange={(event, value) => handleInputChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            hiddenLabel
            InputProps={{
              ...params.InputProps,
              ...(props.isShowBackButton && {
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className="cursor-pointer start-adornment"
                    onClick={props.onClickBackButton}
                  >
                    <ImageIcon iconClassName="image-icon-green arrow-left" />
                  </InputAdornment>
                ),
              }),
              endAdornment: (
                <InputAdornment position="start" className="end-adornment">
                  <Button
                    variant="contained"
                    onClick={() => props.onClickSearchButton(inputValue)}
                  >
                    <ImageIcon iconClassName="image-icon-white search" />
                  </Button>
                </InputAdornment>
              ),
              sx: { backgroundColor: "transparent" },
              onFocus: () => setIsShowPlaceholder(false),
              onBlur: () => setIsShowPlaceholder(!inputValue && !selected),
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                }
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {optionImageKeyName && (
              <Box
                minWidth={50}
                maxWidth={50}
                width={50}
                height={50}
                borderRadius={"100%"}
                overflow={"hidden"}
                marginRight={2}
              >
                <img
                  loading="lazy"
                  className="img-object-cover"
                  src={
                    option[optionImageKeyName]
                      ? `${apiUrl}/storages/${option.storage?.id}`
                      : LogoGray
                  }
                  alt={optionLabelKeyName ? option[optionLabelKeyName] : option}
                />
              </Box>
            )}
            <Typography
              variant="body1"
              color={StyleVariables["color-primary"]}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
            >
              {optionLabelKeyName ? option[optionLabelKeyName] : option}
            </Typography>
          </Box>
        )}
      />
    </Box>
  );
};

export default SearchWithAnimatePlaceholder;
