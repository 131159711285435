export const Paths = {
  notFound: "*",
  home: "/",
  aboutUs: "/about",
  contactUs: "/contact",
  resetPassword: "/reset",
  socialLogin: "/social-login",
  profile: {
    me: "/me",
    edit: "/profile-edit",
  },
  marketPlace: {
    list: "/marketplace",
    post: "/post",
    postDetail: (id: string) => `/marketplace/post/${id}`,
    postEdit: (id: string) => `/marketplace/post/${id}/edit`,
    announcerProfile: (id: string) => `/marketplace/announcer/${id}`,
  },
  product: {
    price: {
      today: (id: string) => `/product/${id}/price/today`,
      past: (id: string, date: string) => `/product/${id}/price/${date}`,
    },
    search: (query: string) => `/search?${query}`,
  },
};
