import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useMetaTags } from "react-metatags-hook";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import useSSR from "use-ssr";
import GeneralLayout from "../../layouts/General";
import { Box, Container, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { TypesProduct as TypesProductConstant } from "../../constants/Product";
import { Paths as PathsConstant } from "../../constants/Route";
import { MetaTags as MetaTagsConstant } from "../../constants/MetaTag";
import { useStores } from "../../stores";
import ButtonGroupToggle from "../../components/toggles/ButtonGroup";
import UserInformation from "../../components/UserInformation";
import PostTable from "../../components/tables/Post";
import {
  GetMyPosts as GetMyPostsService,
  DeletePost as DeletePostService,
} from "../../services/Marketplace";
import {
  Post as PostModel,
  GetPostsQuery as GetPostsQueryModel,
} from "../../models/Marketplace";
import ConfirmDeleteModal from "../../components/modals/ConfirmDelete";

type Props = {};

const MyProfile = observer((props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isBrowser } = useSSR();
  const { UserStore, SnackbarStore } = useStores();
  const initPostSelected: PostModel = {
    title: "",
    description: "",
    type: TypesProductConstant[0].value,
    volume: 0,
    unit: "",
    price: 0,
  };
  const typeOptions = [
    { label: "product.type.all", value: "all" },
    ...TypesProductConstant,
  ];
  const [filter, setFilter] = useState<GetPostsQueryModel>({
    sortBy: "createdAt",
    sortOrder: "DESC",
    type: typeOptions[0].value,
    limit: 10,
    skip: 0,
    keyword: "",
  });
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [isEndOffPostsList, setIsEndOffPostsList] = useState<boolean>(false);
  const [windowPosition, setWindowPosition] = useState<number>(
    isBrowser ? window.scrollY : 0
  );
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [currenPostSelected, setCurrenPostSelected] = useState<PostModel>({
    ...initPostSelected,
  });

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  useEffect(() => {
    fetchMyPosts(filter, posts);
    isBrowser && window.addEventListener("scroll", debounced);
    return () => {
      window.removeEventListener("scroll", debounced);
    };
  }, []);

  const fetchMyPosts = async (
    query: GetPostsQueryModel,
    currentPosts: PostModel[]
  ) => {
    SnackbarStore.onOpenSnackbarProcessing(t("marketplace.postIsFetching"));
    const { type, ...queryDeconstruct } = query;
    const tempPosts: PostModel[] = [...currentPosts];
    const response = await GetMyPostsService({
      ...queryDeconstruct,
      ...(query.type !== "all" && { type }),
    });
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        response.message
          ? `${response.statusCode} ${response.message}`
          : t("marketplace.postFetchFail")
      );
      return;
    }
    tempPosts.push(...response.data);
    setPosts([...tempPosts]);
    setIsEndOffPostsList(!response.data?.length);
  };

  const onFilterChange = (key: string, value: any) => {
    const filterObj: any = { ...filter };
    filterObj[key] = value;
    filterObj.skip = 0;
    setFilter({ ...filterObj });
    fetchMyPosts(filterObj, []);
  };

  const debounced = useDebouncedCallback(() => {
    setWindowPosition(isBrowser ? window.scrollY : 0);
    isBrowser &&
      window.scrollY > windowPosition &&
      !SnackbarStore.isOpenSnackbarProcessing &&
      !isEndOffPostsList &&
      fetchMyPosts(
        {
          ...filter,
          skip: posts.length,
        },
        posts
      );
  }, 500);

  const onOpenDeleteModal = (post: PostModel) => {
    setCurrenPostSelected(post);
    setIsOpenDeleteModal(true);
  };
  const onCloseDeleteModal = () => {
    setCurrenPostSelected({ ...initPostSelected });
    setIsOpenDeleteModal(false);
  };

  const onDeletePost = async () => {
    SnackbarStore.onOpenSnackbarProcessing(
      t("marketplace.deletePost.processing")
    );
    const response = await DeletePostService(currenPostSelected.id || "");
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        response.message
          ? `${response.statusCode} ${response.message}`
          : t("marketplace.deletePost.fail")
      );
      return;
    }
    onCloseDeleteModal();
    await fetchMyPosts(filter, []);
  };

  return (
    <GeneralLayout>
      <Box className="bg-gradient-full" flex={1}>
        <UserInformation
          email={UserStore.user.email}
          username={UserStore.user.username}
          description={UserStore.user.user?.description}
          imageUrl={
            UserStore.user.user?.storage?.id
              ? `${apiUrl}/storages/${UserStore.user.user?.storage?.id}`
              : ""
          }
          phoneNumber={UserStore.user.user?.phoneNumber || ""}
          isProfile={true}
        />
        <Box className="container-content">
          <Container>
            <Box paddingY={4}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Typography variant="h4" color={StyleVariables["color-white"]}>
                  {t("marketplace.title")}
                </Typography>
                <Box marginLeft={{ xs: 0, md: "auto" }}>
                  <ButtonGroupToggle
                    options={typeOptions}
                    value={filter.type}
                    labelAsI18n={true}
                    onChange={(value: string) => onFilterChange("type", value)}
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <PostTable
                  posts={[...posts]}
                  withActions={true}
                  onDelete={(post: PostModel) => onOpenDeleteModal(post)}
                  onEdit={(post: PostModel) =>
                    navigate(PathsConstant.marketPlace.postEdit(`${post.id}`))
                  }
                />
              </Box>
            </Box>
          </Container>
        </Box>
        <ConfirmDeleteModal
          isOpen={isOpenDeleteModal}
          title={t("marketplace.deletePost.title")}
          description={t("marketplace.deletePost.description")}
          information={currenPostSelected.title}
          onSubmit={onDeletePost}
          onClose={onCloseDeleteModal}
        />
      </Box>
    </GeneralLayout>
  );
});

export default MyProfile;
