import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useStores } from "../stores";
import ProcessingSnackbar from "../components/snackbars/Processing";
import SuccessSnackbar from "../components/snackbars/Success";
import ErrorSnackbar from "../components/snackbars/Error";
import { GetMe as GetMeService } from "../services/User";

type Props = { children: JSX.Element };

const General = observer((props: Props) => {
  const { SnackbarStore, UserStore } = useStores();

  useEffect(() => {
    const handleAutoLogin = async () => {
      if (localStorage.accessToken) {
        const response = await GetMeService();
        if (response.success) {
          UserStore.onSetIsLoggedIn(true);
          UserStore.onSetUser(response.data || { username: "", email: "" });
        }
      }
    };
    handleAutoLogin();
  }, []);

  return (
    <Box minHeight={"100vh"}>
      <ProcessingSnackbar
        isOpen={SnackbarStore.isOpenSnackbarProcessing}
        message={SnackbarStore.message}
        onClose={SnackbarStore.onCloseSnackbarProcessing}
      />
      <SuccessSnackbar
        isOpen={SnackbarStore.isOpenSnackbarSuccess}
        message={SnackbarStore.message}
        onClose={SnackbarStore.onCloseSnackbarSuccess}
      />
      <ErrorSnackbar
        isOpen={SnackbarStore.isOpenSnackbarError}
        message={SnackbarStore.message}
        onClose={SnackbarStore.onCloseSnackbarError}
      />
      <Header />
      <Box
        minHeight={{ xs: "calc(100vh - 139px)", md: "calc(100vh - 113px)" }}
        display={"flex"}
        flexDirection={"column"}
      >
        {props.children}
      </Box>
      <Footer />
    </Box>
  );
});

export default General;
