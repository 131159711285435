import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import EnLocale from "./locales/En";
import ThLocale from "./locales/Th";

i18next.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources: {
    en: {
      translation: {
        ...EnLocale,
      },
    },
    th: {
      translation: {
        ...ThLocale,
      },
    },
  },
  lng: "th", // if you're using a language detector, do not define the lng option
  fallbackLng: "th",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export default i18next;
