const validateFormatEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return regex.test(email);
};
const phoneNumberFormat = (value: string) => {
  const string = value.replace(/[^0-9\-]/g, ""); //eslint-disable-line
  const newString = string.substring(0, 15);
  return newString;
};
const nameFormat = (value: string) => {
  const newString = value.replace(
    /[0-9\๑\๒\๓\๔\๕\๖\๗\๘\๙\๐\@\_\.\-\,\/\!\#\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\:\;\'\"\|\~\`\\]/g, //eslint-disable-line
    ""
  );
  return newString;
};
const numberFormat = (text: string) => {
  const string = text.replace(/[^0-9\.]/g, ""); //eslint-disable-line
  return string;
};

export {
  validateFormatEmail as ValidateFormatEmail,
  phoneNumberFormat as PhoneNumberFormat,
  nameFormat as NameFormat,
  numberFormat as NumberFormat,
};
