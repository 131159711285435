import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Router as RouterModel } from "./models/Router";
import Routers from "./Router";
import { ThemeProvider, createTheme } from "@mui/material";
import Theme from "./themes";
import "./assets/styles/Main.scss";
import "./assets/styles/InputSearch.scss";
import "./plugins/i18n";
import ReactGA from "react-ga4";
import { ReactTagManager } from "react-gtm-ts";
import OneSignal from "react-onesignal";
import AuthGuardMiddleware from "./middleware/AuthGuard";

function App() {
  let isInitialize: boolean = false;
  const ga4MeasurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID;
  const googleTagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
  const onesignalId = process.env.REACT_APP_ONESIGNAL_ID;

  useEffect(() => {
    !isInitialize && init();
  }, []);

  const init = async () => {
    isInitialize = true;
    ReactGA.initialize(ga4MeasurementId || "");
    ReactTagManager.init({
      code: googleTagManagerId || "", // GTM Code
      debug: false, // debug mode (default false)
      performance: true, // starts GTM only after user interaction (improve initial page load)
    });
    await OneSignal.init({
      appId: onesignalId || "",
      allowLocalhostAsSecureOrigin: true,
    });
    OneSignal.Slidedown.promptPush();
  };

  const Router = () => {
    const routerMapping = Routers.map(
      ({ path, title, element, auth }: RouterModel) => {
        return (
          <Route
            key={title}
            path={`/${path}`}
            element={
              !auth ? element : <AuthGuardMiddleware children={element} />
            }
          />
        );
      }
    );

    return <Routes>{routerMapping}</Routes>;
  };

  const theme = createTheme({
    ...Theme,
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
