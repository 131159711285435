import React from "react";
import { FormControl, Select, MenuItem, Typography } from "@mui/material";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

interface Props {
  options: any[];
  labelKey?: string;
  valueKey?: string;
  selected: any;
  placeholder?: string;
  isError?: boolean;
  isDisabled?: boolean;
  onSelected: (value: any) => void;
}

const Selection = (props: Props) => {
  const handleChange = (event: any) => {
    const value = event.target?.value;
    props.onSelected(props.valueKey ? JSON.parse(value) : value);
  };

  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        error={props.isError}
        value={
          !props.selected ||
          !props.options.length ||
          (props.valueKey && !props.selected[props.valueKey])
            ? ""
            : (props.valueKey && JSON.stringify(props.selected)) ||
              props.selected
        }
        onChange={handleChange}
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
        renderValue={(selected: any) => {
          if (
            !selected ||
            (props.valueKey && !JSON.parse(selected)[props.valueKey])
          ) {
            return (
              <Typography
                variant="body1"
                color={StyleVariables["color-placeholder"]}
                display="block"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {props.placeholder || "---- เลือก ----"}
              </Typography>
            );
          } else {
            return (
              <Typography
                variant="body1"
                color={StyleVariables["color-black"]}
                display="inline-block"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                className="dropdown-selected-text"
              >
                {props.labelKey
                  ? JSON.parse(selected)[props.labelKey]
                  : selected}
              </Typography>
            );
          }
        }}
        disabled={props.isDisabled}
        MenuProps={{
          PaperProps: {
            sx: { maxHeight: 300, maxWidth: "calc(40% - 96px)" },
          },
        }}
      >
        {props.options?.map((option, index) => (
          <MenuItem
            key={option.id}
            value={props.valueKey ? JSON.stringify(option) : option}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "100%",
              display: "block",
              color: StyleVariables["color-black"],
            }}
          >
            {props.labelKey ? option[props.labelKey] : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Selection;
