import React from "react";
import { useTranslation } from "react-i18next";
import { useMetaTags } from "react-metatags-hook";
import GeneralLayout from "../layouts/General";
import { Box, Container, Grid, Typography } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import FeatureAverageImage from "../assets/images/FeaturePriceAverage.png";
import FeatureGraphImage from "../assets/images/FeatureGraph.png";
import FeaturePricePastImage from "../assets/images/FeaturePricePast.png";
import { MetaTags as MetaTagsConstant } from "../constants/MetaTag";

type Props = {};

const AboutUs = (props: Props) => {
  const { t } = useTranslation();

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );
  return (
    <GeneralLayout>
      <>
        <Box className="about-us-description-container">
          <Box className="container-content">
            <Box
              textAlign={"center"}
              maxWidth={705}
              marginX={"auto"}
              paddingTop={1}
              paddingBottom={12}
              color={StyleVariables["color-white"]}
            >
              <Typography variant="h1" marginTop={12} marginBottom={4}>
                {t("aboutUs.heading.comparePrice")}
                <br />
                {t("aboutUs.heading.product")}
              </Typography>
              <Typography variant="subtitle1" marginBottom={2}>
                {t("aboutUs.subtitle")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="container-content">
          <Container>
            <Box padding={4} paddingBottom={12} textAlign={"center"}>
              <Typography
                variant="h1"
                color={StyleVariables["color-primary"]}
                marginBottom={6}
              >
                {t("aboutUs.mainFeatures.title")}
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <img
                    src={FeatureAverageImage}
                    width={200}
                    height={200}
                    alt="ฟีเจอร์หลัก-คำนวณราคาเฉลี่ย"
                  />
                  <Typography
                    variant="h5"
                    color={StyleVariables["color-black"]}
                    marginY={2}
                  >
                    {t("aboutUs.mainFeatures.first.title")}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={StyleVariables["color-neutral"]}
                    marginY={2}
                  >
                    {t("aboutUs.mainFeatures.first.description")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={FeatureGraphImage}
                    width={200}
                    height={200}
                    alt="ฟีเจอร์หลัก-แสดงผลเป็นกราฟ"
                  />{" "}
                  <Typography
                    variant="h5"
                    color={StyleVariables["color-black"]}
                    marginY={2}
                  >
                    {t("aboutUs.mainFeatures.second.title")}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={StyleVariables["color-neutral"]}
                    marginY={2}
                  >
                    {t("aboutUs.mainFeatures.first.description")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src={FeaturePricePastImage}
                    width={200}
                    height={200}
                    alt="ฟีเจอร์หลัก-ดูราคาย้อนหลัง"
                  />{" "}
                  <Typography
                    variant="h5"
                    color={StyleVariables["color-black"]}
                    marginY={2}
                  >
                    {t("aboutUs.mainFeatures.third.title")}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={StyleVariables["color-neutral"]}
                    marginY={2}
                  >
                    {t("aboutUs.mainFeatures.third.description")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </>
    </GeneralLayout>
  );
};

export default AboutUs;
