import StyleVariables from "../assets/styles/Variable.module.scss";

const toggleGroup = {
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        height: 42,
        "& .MuiButtonBase-root": {
          minWidth: 96,
          border: `1px solid ${StyleVariables["color-primary"]}`,
          color: StyleVariables["color-neutral"],
          background: StyleVariables["color-neutralDark"],
          "&:hover": {
            background: StyleVariables["color-neutralDark"],
          },
          "&.Mui-selected": {
            color: StyleVariables["color-warning"],
            background: StyleVariables["color-white"],
            "&:hover": {
              background: StyleVariables["color-white"],
            },
          },
        },
      },
    },
  },
};

export { toggleGroup as ToggleGroup };
