import React from "react";
import { observer } from "mobx-react";
import { Box, Modal, Button, Typography, Fade } from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "../../assets/styles/Modal.scss";
import { Close as CloseIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  information?: string;
  onClose: () => void;
  onSubmit: () => void;
};

const ConfirmDelete = observer((props: Props) => {
  const { t } = useTranslation();

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
    >
      <Fade in={props.isOpen}>
        <Box className="modal modal-md">
          <Box id="modal-title">
            <Box
              color={StyleVariables["color-neutral"]}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              paddingTop={2}
              paddingX={2}
              className="cursor-pointer"
              onClick={handleClose}
            >
              <CloseIcon />
            </Box>
            <Box className="modal-body">
              <Box textAlign={"center"}>
                <WarningIcon
                  sx={{
                    fontSize: 40,
                    color: StyleVariables["color-error"],
                  }}
                />
              </Box>
              <Box textAlign="center" color={StyleVariables["color-black"]}>
                <Typography variant="h6">{props.title}</Typography>
                <Box
                  marginTop={3}
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  display={{ xs: "block", lg: "flex" }}
                >
                  <Typography variant="body1">{props.description}</Typography>
                </Box>
                {props.information && (
                  <Typography
                    variant="body1"
                    color={StyleVariables["color-neutral"]}
                  >
                    "{props.information}"
                  </Typography>
                )}
              </Box>
              <Box
                display={"grid"}
                gridTemplateColumns={{ xs: "1fr", sm: "repeat(2, 150px)" }}
                justifyContent={"center"}
                gap={2}
                marginTop={3}
                marginBottom={2}
              >
                <Box>
                  <Button
                    variant="outlined-cancel"
                    fullWidth
                    onClick={props.onClose}
                  >
                    {t("common.cancel")}
                  </Button>
                </Box>
                <Box gridRow={{ xs: 1, sm: "auto" }}>
                  <Button
                    variant="contained-delete"
                    fullWidth
                    onClick={props.onSubmit}
                  >
                    {t("common.delete")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
});

export default ConfirmDelete;
