import React from "react";
import { useNavigate } from "react-router-dom";
import { useMetaTags } from "react-metatags-hook";
import GeneralLayout from "../layouts/General";
import { Box, Button, Typography } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import { Paths as PathsConstant } from "../constants/Route";
import { MetaTags as MetaTagsConstant } from "../constants/MetaTag";

type Props = {};

const NotFound = (props: Props) => {
  const navigate = useNavigate();

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  return (
    <GeneralLayout>
      <Box
        className="bg-gradient-full"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        color={StyleVariables["color-white"]}
      >
        <Box marginBottom={10} textAlign={"center"}>
          <Typography variant="h2">This page could not be found</Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={() => navigate(PathsConstant.home)}
          >
            กลับสู่หน้าแรก
          </Button>
        </Box>
      </Box>
    </GeneralLayout>
  );
};

export default NotFound;
