import { observable, makeAutoObservable, action } from "mobx";
import { UserCredential as UserCredentialModel } from "../models/User";
import OneSignal from "react-onesignal";
import {
  LoginOneSignal as LoginOneSignalService,
  UpdateExternalId as UpdateExternalIdService,
} from "../services/OneSignal";

export default class UserStore {
  @observable isLoggedIn: boolean = false;
  @observable isOpenModalLogin: boolean = false;
  @observable user: UserCredentialModel = {
    username: "",
    email: "",
  };

  constructor() {
    makeAutoObservable(this);
  }
  @action
  onOpenModalLogin = () => {
    this.isOpenModalLogin = true;
  };
  @action
  onCloseModalLogin = () => {
    this.isOpenModalLogin = false;
  };
  @action
  onSetIsLoggedIn(value: boolean) {
    this.isLoggedIn = value;
    if (value) {
      const { optedIn } = OneSignal.User.PushSubscription;
      OneSignal.Notifications.addEventListener(
        "permissionChange",
        (isEnabled: boolean) => {
          if (isEnabled) {
            LoginOneSignalService();
            UpdateExternalIdService();
          }
        }
      );
      LoginOneSignalService();
      optedIn && UpdateExternalIdService();
    }
  }

  @action
  onSetUser = (user: UserCredentialModel) => {
    this.user = { ...user };
  };

  @action
  onClearStore() {
    this.isLoggedIn = false;
    this.user = {
      username: "",
      email: "",
    };
  }
}
