import React, { useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMetaTags } from "react-metatags-hook";
import { Box, Container } from "@mui/material";
import { Paths as PathsConstant } from "../../constants/Route";
import { MetaTags as MetaTagsConstant } from "../../constants/MetaTag";
import GeneralLayout from "../../layouts/General";

type Props = {};

const Search = (props: Props) => {
  const gcseScriptUrl = process.env.REACT_APP_GCSE_SCRIPT_URL;
  const navigate = useNavigate();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  useEffect(() => {
    const queryString = query.get("q");
    queryString ? initGoogleSearchEngine() : navigate(PathsConstant.home);
  }, []);

  const initGoogleSearchEngine = () => {
    const script = document.createElement("script");
    document.head.append(script);
    script.src = `${gcseScriptUrl}`;
  };

  return (
    <GeneralLayout>
      <Box className="container-content bg-gradient-full">
        <Container>
          <Box paddingY={3}>
            <div className="gcse-searchresults-only" />
          </Box>
        </Container>
      </Box>
    </GeneralLayout>
  );
};

export default Search;
