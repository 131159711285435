import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Input, Typography, Button } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  Login as LoginModel,
  LoginIsInvalid as LoginIsInvalidModel,
} from "../../models/User";
import { ValidateFormatEmail as ValidateFormatEmailUtil } from "../../utils/InputFormat";

type Props = {
  onClickRegister: () => void;
  onClickForgotPassword: () => void;
  onLogIn: (payload: LoginModel) => void;
};

function Login(props: Props) {
  const { t } = useTranslation();
  const [form, setForm] = useState<LoginModel>({
    email: "",
    password: "",
  });
  const [formIsInvalid, setFormIsInvalid] = useState<LoginIsInvalidModel>({
    email: false,
    password: false,
  });

  const onInputChange = (value: string, key: string) => {
    const formObj: any = { ...form };
    const formIsInvalidObj: any = { ...formIsInvalid };
    formObj[key] = value;
    formIsInvalidObj[key] = false;
    key === "email" &&
      (formIsInvalidObj.email = Boolean(!ValidateFormatEmailUtil(value)));
    setForm({ ...formObj });
    setFormIsInvalid({ ...formIsInvalidObj });
  };

  const onValidateForm = () => {
    const formIsInvalidObj: LoginIsInvalidModel = { ...formIsInvalid };
    formIsInvalidObj.email = Boolean(
      !form.email || !ValidateFormatEmailUtil(form.email)
    );
    formIsInvalidObj.password = Boolean(!form.password);
    setFormIsInvalid({ ...formIsInvalidObj });
    return Object.values(formIsInvalid).every((v) => v === false);
  };

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    const formIsValid: boolean = onValidateForm();
    if (formIsValid) {
      props.onLogIn({ email: form.email, password: form.password });
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        color={StyleVariables["color-black"]}
        marginBottom={3}
      >
        {t("authentication.signIn")}
      </Typography>
      <form onSubmit={handleLogin}>
        <Box marginBottom={3}>
          <Typography variant="label">
            {t("authentication.yourEmail")}
          </Typography>
          <Input
            placeholder={t("authentication.email")}
            type="email"
            value={form.email}
            error={formIsInvalid.email}
            onChange={(e) => onInputChange(e.target.value, "email")}
          />
        </Box>
        <Box>
          <Typography variant="label">
            {t("authentication.password")}
          </Typography>
          <Input
            placeholder={t("authentication.password")}
            type="password"
            value={form.password}
            error={formIsInvalid.password}
            onChange={(e) => onInputChange(e.target.value, "password")}
          />
        </Box>
        <Box marginBottom={4} textAlign={"right"}>
          <Typography
            variant="link"
            color={StyleVariables["color-primary"]}
            style={{ fontSize: 14 }}
            onClick={props.onClickForgotPassword}
          >
            {t("authentication.forgotPassword")} ?
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" className="full-width" type="submit">
            {t("common.submit")}
          </Button>
        </Box>
      </form>
      <Box marginTop={3} justifyContent={"center"} display={"flex"}>
        <Typography variant="body1" color={StyleVariables["color-neutral"]}>
          {t("authentication.doNotHaveAnAccount")}
        </Typography>
        <Typography
          variant="link"
          marginLeft={1}
          color={StyleVariables["color-primary"]}
          onClick={props.onClickRegister}
        >
          {t("authentication.register")}
        </Typography>
      </Box>
    </>
  );
}

export default Login;
