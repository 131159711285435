import StyleVariables from "../assets/styles/Variable.module.scss";
import StyleButton from "./Button";
import StyleInput from "./Input";
import {
  Typography as StyleTypography,
  TypographyComponent as StyleTypographyComponent,
} from "./Typography";
import { ToggleGroup as StyleToggleGroup } from "./Toggle";
import StyleSelect from "./Select";
import StylePaper from "./Paper";

const Theme: any = {
  spacing: Number(StyleVariables.spacing),
  palette: {
    primary: {
      main: StyleVariables["color-primary"],
      dark: StyleVariables["color-primaryDark"],
      contrastText: StyleVariables["color-white"],
    },
    secondary: {
      main: StyleVariables["color-secondary"],
      contrastText: StyleVariables["color-white"],
    },
  },
  typography: {
    ...StyleTypography,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 426,
      md: 769,
      lg: 1201,
      xl: 1441,
    },
  },
  components: {
    ...StyleTypographyComponent,
    ...StyleButton,
    ...StyleInput,
    ...StyleToggleGroup,
    ...StyleSelect,
    ...StylePaper,
  },
};

export default Theme;
