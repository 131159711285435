const searchProductPlaceholderTh = [
  "ค้นหาสินค้าเกษตร",
  "มะม่วง",
  "มะละกอ",
  "กล้วย",
  "ส้ม",
];

const searchProductPlaceholderEn = [
  "Search agricultural product",
  "Mango",
  "Papaya",
  "Banana",
  "Orange",
];

export {
  searchProductPlaceholderTh as SearchProductPlaceholderTh,
  searchProductPlaceholderEn as SearchProductPlaceholderEn,
};
