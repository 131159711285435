import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../stores";
import StyleVariables from "../assets/styles/Variable.module.scss";
import ImageIcon from "./ImageIcon";
import { Logout as LogoutService } from "../services/User";
import { Paths as PathsConstant } from "../constants/Route";

type Props = {
  email: string;
  username: string;
  description?: string;
  imageUrl: string;
  phoneNumber: string;
  isProfile?: boolean;
};

const UserInformation = observer((props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { UserStore } = useStores();

  const handleLogout = () => {
    UserStore.onClearStore();
    LogoutService();
  };

  return (
    <Box paddingTop={3} paddingBottom={4} paddingX={2} className="bg-white">
      <Container>
        <Box
          display={"grid"}
          gridTemplateColumns={{ xs: "1fr", md: "3fr 9fr" }}
          alignItems={"center"}
          gap={2}
          marginBottom={4}
          borderBottom={{
            xs: `1px solid ${StyleVariables["color-neutral"]}`,
            md: "none",
          }}
          paddingBottom={{ xs: 2, md: 0 }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            flexDirection={{ xs: "column", md: "row" }}
            gap={{ xs: 2, md: 4 }}
          >
            {props.imageUrl ? (
              <Box
                width={56}
                height={56}
                minWidth={56}
                minHeight={56}
                borderRadius={"100%"}
                overflow={"hidden"}
              >
                <img
                  src={props.imageUrl}
                  alt={`profile-${props.username}`}
                  className="img-object-cover"
                />
              </Box>
            ) : (
              <Box className="profile-img-avatar-default">
                <ImageIcon iconClassName="image-icon-gray avatar" />
              </Box>
            )}
            <Typography
              variant="h5"
              color={StyleVariables["color-black"]}
              className="text-2-ellipse"
            >
              {UserStore.isLoggedIn
                ? props.username || "-"
                : t("profile.memberOnly")}
            </Typography>
          </Box>
          <Typography
            variant="body1"
            className="text-2-ellipse"
            color={StyleVariables["color-black"]}
            textAlign={{ xs: "center", md: "left" }}
          >
            {props.description || "-"}
          </Typography>
        </Box>
        <Box
          display={"grid"}
          gap={2}
          alignItems={"center"}
          gridTemplateColumns={{ xs: "1fr", md: "3fr 3fr 6fr" }}
          color={StyleVariables["color-neutral"]}
        >
          <Box
            display={"flex"}
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <Typography variant="body1" color={StyleVariables["color-primary"]}>
              {t("profile.email")} :
            </Typography>
            <Typography variant="body1" marginLeft={1}>
              {UserStore.isLoggedIn
                ? props.email || "-"
                : t("profile.memberOnly")}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={{ xs: "center", md: "flex-start" }}
            marginBottom={{ xs: 2, md: 0 }}
          >
            <Typography variant="body1" color={StyleVariables["color-primary"]}>
              {t("profile.phoneNumber")} :
            </Typography>
            <Typography variant="body1" marginLeft={1}>
              {UserStore.isLoggedIn
                ? props.phoneNumber || "-"
                : t("profile.memberOnly")}
            </Typography>
          </Box>
          {props.isProfile && (
            <Box
              display={"flex"}
              gap={3}
              justifyContent={{ xs: "center", md: "flex-end" }}
            >
              <Button
                variant="outlined"
                onClick={() => navigate(PathsConstant.profile.edit)}
              >
                {t("profile.edit")}
              </Button>
              <Button variant="contained-warning" onClick={handleLogout}>
                {t("authentication.signOut")}
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
});

export default UserInformation;
