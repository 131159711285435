import { observable, makeAutoObservable, action } from "mobx";

export default class SnackbarStore {
  @observable isOpenSnackbarProcessing: boolean = false;
  @observable isOpenSnackbarSuccess: boolean = false;
  @observable isOpenSnackbarError: boolean = false;
  @observable message: string = "";

  constructor() {
    makeAutoObservable(this);
  }
  @action
  onOpenSnackbarProcessing = (message: string) => {
    this.isOpenSnackbarProcessing = true;
    this.message = message;
  };

  @action
  onCloseSnackbarProcessing = () => {
    this.isOpenSnackbarProcessing = false;
  };

  @action
  onOpenSnackbarSuccess = (message: string) => {
    this.isOpenSnackbarSuccess = true;
    this.message = message;
  };
  @action
  onCloseSnackbarSuccess = () => {
    this.isOpenSnackbarSuccess = false;
  };

  @action
  onOpenSnackbarError = (message: string) => {
    this.isOpenSnackbarError = true;
    this.message = message;
  };
  @action
  onCloseSnackbarError = () => {
    this.isOpenSnackbarError = false;
  };
}
