import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from "chart.js";
import { Line } from "react-chartjs-2";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  labels: string[];
  datasets: any[];
  options?: {};
};

const LineGraph = (props: Props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  defaults.font.family = StyleVariables["font-family-primary"];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          font: {
            size: 10,
            family: "Kanit",
          },
          usePointStyle: true,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: StyleVariables["color-white"],
        titleColor: StyleVariables["color-black"],
        titleSpacing: Number(StyleVariables.spacing) * 3,
        titleMarginBottom: Number(StyleVariables.spacing),
        bodyColor: StyleVariables["color-neutral"],
        bodySpacing: Number(StyleVariables.spacing) * 2,
        padding: Number(StyleVariables.spacing),
        cornerRadius: 4,
        boxPadding: 3,
        borderColor: StyleVariables["color-neutralDark"],
        borderWidth: 1,
        usePointStyle: true,
      },
    },
  };

  return (
    <Line
      options={{
        ...options,
        ...props.options,
      }}
      data={{ labels: props.labels, datasets: props.datasets }}
    />
  );
};

export default LineGraph;
