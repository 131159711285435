const Select: any = {
  MuiSelect: {
    styleOverrides: {
      select: {
        height: "100%",
        ".MuiTypography-root": {
          width: "98%",
          fontWeight: 300,
        },
      },
    },
  },
};

export default Select;
