import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useStores } from "../stores";
import StyleVariables from "../assets/styles/Variable.module.scss";
import CommentInput from "./inputs/Comment";
import { PostComment as PostCommentModel } from "../models/Marketplace";
import { DateFromNow as DateFromNowUtil } from "../utils/DateFormat";
import ImageIcon from "./ImageIcon";
import ConfirmDeleteModal from "./modals/ConfirmDelete";

type Props = {
  comments: PostCommentModel[];
  onComment: (comment: string) => void;
  onUpdateComment: (id: number | string, comment: string) => void;
  onDeleteComment: (comment: PostCommentModel) => void;
};

const Comment = observer((props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const { UserStore } = useStores();
  const [comments, setComments] = useState<PostCommentModel[]>([]);
  const [isOpenDeleteModel, setIsOpenDeleteModal] = useState<boolean>(false);
  const [currentComment, setCurrentComment] = useState<PostCommentModel>({
    comment: "",
  });

  useEffect(() => {
    setComments([...props.comments]);
  }, [props.comments]);

  const handleEditComment = (index: number, isEditing: boolean) => {
    const arrComments: PostCommentModel[] = [...comments];
    arrComments[index].isEditing = isEditing;
    setComments([...arrComments]);
  };

  const handleUpdateComment = (id: string | number, text: string) => {
    props.onUpdateComment(id || "", text);
  };

  const onOpenDeleteCommentModal = (comment: PostCommentModel) => {
    setCurrentComment(comment);
    setIsOpenDeleteModal(true);
  };

  const onCloseDeleteCommentModal = () => {
    setIsOpenDeleteModal(false);
    setCurrentComment({
      comment: "",
    });
  };

  const handleDeleteComment = async () => {
    await props.onDeleteComment(currentComment);
    onCloseDeleteCommentModal();
  };

  return (
    <>
      <Box>
        <Typography
          variant="h4"
          color={StyleVariables["color-black"]}
          marginBottom={3}
        >
          {t("marketplace.comment.title")} ({comments.length})
        </Typography>
        <CommentInput
          onComment={(comment: string) => {
            props.onComment(comment);
          }}
        />
        <Box marginTop={4} paddingX={{ xs: 2, md: 4 }}>
          {comments.map((comment, index) => {
            return (
              <Box key={index} display={"flex"} gap={3} marginBottom={2}>
                {comment.user?.storage?.id ? (
                  <Box
                    minWidth={24}
                    minHeight={24}
                    width={24}
                    height={24}
                    overflow={"hidden"}
                    borderRadius={"100%"}
                  >
                    <img
                      src={`${apiUrl}/storages/${comment.user?.storage?.id}`}
                      alt={comment.user?.firstName}
                      className="img-object-cover"
                      loading="lazy"
                    />
                  </Box>
                ) : (
                  <Box className="img-avatar-default-small">
                    <ImageIcon iconClassName="image-icon-gray avatar" />
                  </Box>
                )}
                <Box width={comment.isEditing ? "100%" : "auto"}>
                  <Box
                    bgcolor={StyleVariables["color-background"]}
                    borderRadius={2}
                    padding={1}
                  >
                    <Typography
                      variant="h6"
                      color={StyleVariables["color-primary"]}
                    >
                      {(comment.user?.userCredentials &&
                        comment.user?.userCredentials[0]?.username) ||
                        "-"}
                    </Typography>
                    {!comment.isEditing ? (
                      <Typography
                        variant="body1"
                        color={StyleVariables["color-black"]}
                      >
                        {comment.comment}
                      </Typography>
                    ) : (
                      <Box marginTop={2}>
                        <CommentInput
                          defaultValue={comment.comment}
                          onComment={(text: string) => {
                            handleUpdateComment(comment.id || "", text);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box display={"flex"} gap={2} marginTop={1}>
                    <Typography
                      variant="body2"
                      color={StyleVariables["color-neutral"]}
                    >
                      {(comment.updatedAt &&
                        DateFromNowUtil(comment.updatedAt)) ||
                        "-"}
                    </Typography>
                    {UserStore.user.user?.id === comment.user?.id && (
                      <Box display={"flex"} gap={2}>
                        {!comment.isEditing ? (
                          <>
                            <Typography
                              variant="body2"
                              color={StyleVariables["color-primary"]}
                              className="text-underline cursor-pointer"
                              onClick={() => handleEditComment(index, true)}
                            >
                              {t("common.edit")}
                            </Typography>
                            <Typography
                              variant="body2"
                              color={StyleVariables["color-error"]}
                              className="text-underline cursor-pointer"
                              onClick={() => onOpenDeleteCommentModal(comment)}
                            >
                              {t("common.delete")}
                            </Typography>
                          </>
                        ) : (
                          <Typography
                            variant="body2"
                            color={StyleVariables["color-neutral"]}
                            className="text-underline cursor-pointer"
                            onClick={() => handleEditComment(index, false)}
                          >
                            {t("common.cancel")}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
          {!props.comments.length && (
            <Typography
              variant="body1"
              paddingY={6}
              textAlign={"center"}
              color={StyleVariables["color-neutral"]}
            >
              {t("marketplace.comment.noData")}
            </Typography>
          )}
        </Box>
      </Box>
      <ConfirmDeleteModal
        isOpen={isOpenDeleteModel}
        title={t("marketplace.comment.delete.title")}
        description={t("marketplace.comment.delete.description")}
        information={currentComment.comment}
        onClose={onCloseDeleteCommentModal}
        onSubmit={handleDeleteComment}
      />
    </>
  );
});

export default Comment;
