import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box, Input, Typography, Button } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  Register as RegisterModel,
  RegisterIsInvalid as RegisterIsInvalidModel,
  Login as LoginModel,
} from "../../models/User";
import { ValidateFormatEmail as ValidateFormatEmailUtil } from "../../utils/InputFormat";
import { Register as RegisterService } from "../../services/User";
import { useStores } from "../../stores";
import ReactGA from "react-ga4";

type Props = {
  onClickLogin: () => void;
  onLogIn: (payload: LoginModel) => void;
};

const Register = observer((props: Props) => {
  const { t } = useTranslation();
  const { SnackbarStore } = useStores();
  const [form, setForm] = useState<RegisterModel>({
    email: "",
    password: "",
    username: "",
  });
  const [formIsInvalid, setFormIsInvalid] = useState<RegisterIsInvalidModel>({
    email: false,
    password: false,
    username: false,
  });

  const onInputChange = (value: string, key: string) => {
    const formObj: any = { ...form };
    const formIsInvalidObj: any = { ...formIsInvalid };
    formObj[key] = value;
    formIsInvalidObj[key] = false;
    key === "email" &&
      (formIsInvalidObj.email = Boolean(!ValidateFormatEmailUtil(value)));
    setForm({ ...formObj });
    setFormIsInvalid({ ...formIsInvalidObj });
  };

  const onValidateForm = () => {
    const formIsInvalidObj: RegisterIsInvalidModel = { ...formIsInvalid };
    formIsInvalidObj.email = Boolean(
      !form.email || !ValidateFormatEmailUtil(form.email)
    );
    formIsInvalidObj.password = Boolean(!form.password);
    formIsInvalidObj.username = Boolean(!form.username);
    setFormIsInvalid({ ...formIsInvalidObj });
    return Object.values(formIsInvalid).every((v) => v === false);
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    const formIsValid: boolean = onValidateForm();
    SnackbarStore.onOpenSnackbarProcessing("");
    if (formIsValid) {
      const response = await RegisterService({ ...form });
      SnackbarStore.onCloseSnackbarProcessing();
      if (response.success) {
        ReactGA.event("sign_up", { method: "Email" });
        props.onLogIn({ email: form.email, password: form.password });
      } else {
        response.error?.statusCode === 700
          ? SnackbarStore.onOpenSnackbarError(
              "อีเมลนี้มีในระบบแล้ว กรุณาคลิกเข้าสู่ระบบ"
            )
          : SnackbarStore.onOpenSnackbarError(
              "ลงทะเบียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
            );
      }
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        color={StyleVariables["color-black"]}
        marginBottom={3}
      >
        {t("authentication.register")}
      </Typography>
      <form onSubmit={handleRegister}>
        <Box marginBottom={3}>
          <Typography variant="label">
            {t("authentication.yourEmail")}
          </Typography>
          <Input
            placeholder={t("authentication.email")}
            type="email"
            value={form.email}
            error={formIsInvalid.email}
            autoComplete="off"
            onChange={(e) => onInputChange(e.target.value, "email")}
          />
        </Box>
        <Box marginBottom={3}>
          <Typography variant="label">
            {t("authentication.password")}
          </Typography>
          <Input
            placeholder={t("authentication.password")}
            type="password"
            value={form.password}
            error={formIsInvalid.password}
            autoComplete="off"
            onChange={(e) => onInputChange(e.target.value, "password")}
          />
        </Box>
        <Box marginBottom={6}>
          <Typography variant="label">{t("profile.displayName")}</Typography>
          <Input
            placeholder={t("profile.displayName")}
            type="text"
            value={form.username}
            error={formIsInvalid.username}
            autoComplete="off"
            onChange={(e) => onInputChange(e.target.value, "username")}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            className="full-width"
            type="submit"
            disabled={SnackbarStore.isOpenSnackbarProcessing}
          >
            {t("common.submit")}
          </Button>
        </Box>
      </form>
      <Box marginTop={3} justifyContent={"center"} display={"flex"}>
        <Typography variant="body1" color={StyleVariables["color-neutral"]}>
          {t("authentication.haveAnAccount")}
        </Typography>
        <Typography
          variant="link"
          marginLeft={1}
          color={StyleVariables["color-primary"]}
          onClick={props.onClickLogin}
        >
          {t("authentication.signIn")}
        </Typography>
      </Box>
    </>
  );
});

export default Register;
