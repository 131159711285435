import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Input, Typography, Button } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import {
  ForgotPassword as ForgotPasswordModel,
  ForgotPasswordIsInvalid as ForgotPasswordIsInvalidModel,
} from "../../models/User";
import { ValidateFormatEmail as ValidateFormatEmailUtil } from "../../utils/InputFormat";

type Props = {
  onClickBackToLogin: () => void;
  onForgotPassword: (email: string) => void;
};

function ForgotPassword(props: Props) {
  const { t } = useTranslation();
  const [form, setForm] = useState<ForgotPasswordModel>({
    email: "",
  });
  const [formIsInvalid, setFormIsInvalid] =
    useState<ForgotPasswordIsInvalidModel>({
      email: false,
    });

  const onInputChange = (value: string, key: string) => {
    const formObj: any = { ...form };
    const formIsInvalidObj: any = { ...formIsInvalid };
    formObj[key] = value;
    formIsInvalidObj[key] = false;
    key === "email" &&
      (formIsInvalidObj.email = Boolean(!ValidateFormatEmailUtil(value)));
    setForm({ ...formObj });
    setFormIsInvalid({ ...formIsInvalidObj });
  };

  const onValidateForm = () => {
    const formIsInvalidObj: ForgotPasswordIsInvalidModel = { ...formIsInvalid };
    formIsInvalidObj.email = Boolean(
      !form.email || !ValidateFormatEmailUtil(form.email)
    );
    setFormIsInvalid({ ...formIsInvalidObj });
    return Object.values(formIsInvalid).every((v) => v === false);
  };

  const handleSubmitForgotPassword = (e: React.FormEvent) => {
    e.preventDefault();
    const formIsValid: boolean = onValidateForm();
    formIsValid && props.onForgotPassword(form.email);
  };

  return (
    <>
      <Typography
        variant="h5"
        color={StyleVariables["color-black"]}
        marginBottom={3}
      >
        {t("authentication.forgotPassword")}
      </Typography>
      <form onSubmit={handleSubmitForgotPassword}>
        <Box marginBottom={4}>
          <Typography variant="label">
            {t("authentication.yourEmail")}
          </Typography>
          <Input
            placeholder={t("authentication.email")}
            type="email"
            value={form.email}
            error={formIsInvalid.email}
            onChange={(e) => onInputChange(e.target.value, "email")}
          />
        </Box>
        <Box>
          <Button variant="contained" className="full-width" type="submit">
            {t("common.submit")}
          </Button>
        </Box>
      </form>
      <Box marginTop={3} justifyContent={"center"} display={"flex"}>
        <Typography
          variant="link"
          marginLeft={1}
          color={StyleVariables["color-primary"]}
          onClick={props.onClickBackToLogin}
        >
          {t("authentication.backToSignIn")}
        </Typography>
      </Box>
    </>
  );
}

export default ForgotPassword;
