import React from "react";
import { Snackbar, Typography, Box } from "@mui/material";
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  isOpen: boolean;
  message: string;
  autoHideDuration?: number;
  onClose?: () => void;
};

const Success = (props: Props) => {
  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <Snackbar
      open={props.isOpen}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={props.autoHideDuration || 1000}
      onClose={handleClose}
    >
      <Box
        bgcolor={StyleVariables["color-white"]}
        color={StyleVariables["color-primary"]}
        borderRadius={"4px"}
        padding={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minWidth={120}
        boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
      >
        <CheckCircleOutlineIcon sx={{ fontSize: 14 }} />
        <Typography variant="body2" marginLeft={1}>
          {props.message}
        </Typography>
      </Box>
    </Snackbar>
  );
};

export default Success;
