import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Input, Typography, Button } from "@mui/material";
import {
  ResetPassword as ResetPasswordModel,
  ResetPasswordIsInvalid as ResetPasswordIsInvalidModel,
} from "../../models/User";
import { ValidateFormatEmail as ValidateFormatEmailUtil } from "../../utils/InputFormat";
import { ResetPassword as ResetPasswordService } from "../../services/User";
import { useStores } from "../../stores";
import { Paths as PathsConstant } from "../../constants/Route";

type Props = {};

const ResetPassword = observer((props: Props) => {
  const navigate = useNavigate();
  const { SnackbarStore, UserStore } = useStores();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();
  const [form, setForm] = useState<ResetPasswordModel>({
    token: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [formIsInvalid, setFormIsInvalid] =
    useState<ResetPasswordIsInvalidModel>({
      token: false,
      email: false,
      password: false,
    });

  useEffect(() => {
    const token: string = query.get("token") || "";
    setForm({ ...form, token });
  }, []);

  const onInputChange = (value: string, key: string) => {
    const formObj: any = { ...form };
    const formIsInvalidObj: any = { ...formIsInvalid };
    formObj[key] = value;
    formIsInvalidObj[key] = false;
    key === "email" &&
      (formIsInvalidObj.email = Boolean(!ValidateFormatEmailUtil(value)));
    setForm({ ...formObj });
    setFormIsInvalid({ ...formIsInvalidObj });
  };

  const onValidateForm = () => {
    const formIsInvalidObj: ResetPasswordIsInvalidModel = { ...formIsInvalid };
    formIsInvalidObj.token = Boolean(!form.token);
    formIsInvalidObj.email = Boolean(
      !form.email || !ValidateFormatEmailUtil(form.email)
    );
    formIsInvalidObj.password = Boolean(!form.password);
    setFormIsInvalid({ ...formIsInvalidObj });
    return Object.values(formIsInvalid).every((v) => v === false);
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    const formIsValid = onValidateForm();
    if (!formIsValid) return;
    const response = await ResetPasswordService({
      ...form,
      confirmPassword: form.password,
    });
    if (response.success) {
      SnackbarStore.onOpenSnackbarSuccess(
        "รีเซ็ตรหัสผ่านสำเร็จ กรุณาเข้าสู่ระบบ"
      );
      navigate(PathsConstant.home);
      UserStore.onOpenModalLogin();
    } else {
      response.error?.statusCode === 700
        ? SnackbarStore.onOpenSnackbarError(
            "Reset code หมดอายุ กรุณาส่งคำขอใหม่อีกครั้ง"
          )
        : SnackbarStore.onOpenSnackbarError(
            "ตั้งค่ารหัสผ่านใหม่ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
          );
    }
  };

  return (
    <>
      <form onSubmit={handleResetPassword}>
        <Box marginBottom={3}>
          <Typography variant="label">Reset Code</Typography>
          <Input
            placeholder="Reset Code"
            type="text"
            value={form.token}
            error={formIsInvalid.token}
            disabled={Boolean(query.get("token"))}
            onChange={(e) => onInputChange(e.target.value, "token")}
          />
        </Box>
        <Box marginBottom={3}>
          <Typography variant="label">อีเมลของคุณ</Typography>
          <Input
            placeholder="อีเมล"
            type="email"
            value={form.email}
            error={formIsInvalid.email}
            autoComplete="off"
            onChange={(e) => onInputChange(e.target.value, "email")}
          />
        </Box>
        <Box marginBottom={6}>
          <Typography variant="label">รหัสผ่านใหม่</Typography>
          <Input
            placeholder="รหัสผ่านใหม่"
            type="password"
            value={form.password}
            error={formIsInvalid.password}
            autoComplete="off"
            onChange={(e) => onInputChange(e.target.value, "password")}
          />
        </Box>
        <Box>
          <Button variant="contained" className="full-width" type="submit">
            ยืนยัน
          </Button>
        </Box>
      </form>
    </>
  );
});

export default ResetPassword;
