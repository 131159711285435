import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Input, InputAdornment } from "@mui/material";
import ImageIcon from "../ImageIcon";

type Props = {
  defaultValue?: string;
  onComment: (comment: string) => void;
};

const Comment = observer((props: Props) => {
  const { t } = useTranslation();
  const { UserStore } = useStores();
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    props.defaultValue && setInputValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleComment = () => {
    if (!UserStore.isLoggedIn) {
      UserStore.onOpenModalLogin();
      return;
    }
    inputValue && props.onComment(inputValue);
    setInputValue("");
  };

  return (
    <Input
      placeholder={t("marketplace.comment.placeholderInput")}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onClick={() => !UserStore.isLoggedIn && UserStore.onOpenModalLogin()}
      endAdornment={
        <InputAdornment
          position="end"
          className="cursor-pointer"
          onClick={handleComment}
        >
          <ImageIcon iconClassName="image-icon-green send" />
        </InputAdornment>
      }
    />
  );
});

export default Comment;
