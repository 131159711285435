import React from "react";
import { Backdrop, Box } from "@mui/material";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Close as CloseIcon } from "@mui/icons-material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  defaultIndex?: number;
  isUseStorage?: boolean;
  images: any[];
  onClose: () => void;
};

const BackdropImage = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return (
    <Backdrop open={true} sx={{ zIndex: 9999, bgcolor: "rgba(0,0,0,0.8)" }}>
      <Box
        position={"absolute"}
        top={{ xs: 64, md: 50 }}
        right={{ xs: 8, md: 50 }}
        bgcolor={StyleVariables["color-white"]}
        borderRadius={"100%"}
        width={30}
        height={30}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        color={StyleVariables["color-black"]}
        className="cursor-pointer"
        onClick={props.onClose}
      >
        <CloseIcon />
      </Box>
      <Box width={"100%"} maxWidth={1201} position={"relative"}>
        <Box paddingX={{ xs: 1, md: 2 }}>
          <Slide
            autoplay={false}
            defaultIndex={props.defaultIndex || 0}
            infinite={props.images.length > 1}
          >
            {props.images.map((image, index) => {
              return (
                <Box
                  key={index}
                  width={{ xs: "80%", md: "100%" }}
                  height={400}
                  marginX={"auto"}
                >
                  <img
                    src={
                      props.isUseStorage
                        ? `${apiUrl}/storages/${image.storage?.id}`
                        : image
                    }
                    alt={image}
                    loading="lazy"
                    className="img-object-contain"
                  />
                </Box>
              );
            })}
          </Slide>
        </Box>
      </Box>
    </Backdrop>
  );
};

export default BackdropImage;
