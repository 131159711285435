import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Box, Typography, Input, Button } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { TypesProduct as TypesProductConstant } from "../../constants/Product";
import ButtonGroupToggle from "../../components/toggles/ButtonGroup";
import SearchWithPopover from "../../components/inputs/SearchWithPopover";
import SelectionDropdown from "../../components/dropdowns/Selection";
import UploadMultipleImageInput from "../../components/inputs/UploadMultipleImage";
import {
  NumberFormat as NumberFormatUtil,
  PhoneNumberFormat as PhoneNumberFormatUtil,
} from "../../utils/InputFormat";
import { useTranslation } from "react-i18next";
import { Product as ProductModel } from "../../models/Product";
import { Search as SearchProductService } from "../../services/Product";

type Props = {
  onSubmit: () => void;
};

const Post = observer((props: Props) => {
  const { t, i18n } = useTranslation();
  const { SnackbarStore, MarketplaceStore } = useStores();
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    !MarketplaceStore.provinces.length && MarketplaceStore.fetchProvinces();
    !MarketplaceStore.units.length && MarketplaceStore.fetchUnits();
  }, []);

  const fetchProductBySearch = async (value: string) => {
    SnackbarStore.onOpenSnackbarProcessing("");
    const response = await SearchProductService(value);
    response.success && setProducts([...response.data]);
    SnackbarStore.onCloseSnackbarProcessing();
  };

  const onInputChange = (value: any, key: string) => {
    const formObj: any = { ...MarketplaceStore.form };
    const formIsInvalidObj: any = { ...MarketplaceStore.formIsInvalid };
    formObj[key] = value;
    formIsInvalidObj[key] = false;
    if (
      key !== "title" &&
      key !== "description" &&
      key !== "lineId" &&
      key !== "phoneNumber1" &&
      key !== "phoneNumber2"
    )
      formObj.title = autoGenerateTitle(key, value);
    MarketplaceStore.onSetForm({ ...formObj });
    MarketplaceStore.onSetFormIsInvalid({ ...formIsInvalidObj });
  };

  const autoGenerateTitle = (key: string, value: any) => {
    const form: any = { ...MarketplaceStore.form };
    const findProductType = TypesProductConstant.find(
      (type) => type.value === (key === "type" ? value : form.type)
    );
    const titleGenerated = `${findProductType?.label}${
      key === "product"
        ? `${i18n.language === "th" ? value.nameTh : value.nameEn}`
        : `${
            i18n.language === "th" ? form.product.nameTh : form.product.nameEn
          }`
    } ${key === "volume" ? value : form.volume} ${
      key === "unit"
        ? `${i18n.language === "th" ? value.nameTh : value.nameEn}`
        : `${i18n.language === "th" ? form.unit?.nameTh : form.unit?.nameEn}`
    } ( ${key === "price" ? value : form.price} / ${
      key === "priceUnit"
        ? `${i18n.language === "th" ? value.nameTh : value.nameEn}`
        : `${
            i18n.language === "th"
              ? form.priceUnit?.nameTh
              : form.priceUnit?.nameEn
          }`
    } ) ${
      key === "province"
        ? `${i18n.language === "th" ? value.nameTh : value.nameEn}`
        : `${
            i18n.language === "th"
              ? form.province?.nameTh
              : form.province?.nameEn
          }`
    }`;
    return titleGenerated;
  };

  const onValidateForm = () => {
    const formObj: any = { ...MarketplaceStore.form };
    const formIsInvalidObj: any = { ...MarketplaceStore.formIsInvalid };
    Object.entries(MarketplaceStore.formIsInvalid).forEach(([key, value]) => {
      formIsInvalidObj[key] = Boolean(
        !formObj[key] ||
          (formObj[key].id !== undefined ? !formObj[key].id : false)
      );
    });
    MarketplaceStore.onSetFormIsInvalid({ ...formIsInvalidObj });
    return Object.values(formIsInvalidObj).every((v) => v === false);
  };

  const onSubmit = () => {
    const isValid = onValidateForm();
    if (!isValid) {
      SnackbarStore.onOpenSnackbarError(t("common.formIsInvalidAlert"));
      return;
    }
    props.onSubmit();
  };

  const handleDeleteImage = (image: any) => {
    MarketplaceStore.onDeletePostImages(image.storage?.id);
  };

  return (
    <>
      {/* Type, ProductType, Province */}
      <Box
        display={"grid"}
        gridTemplateColumns={{ xs: "100%", md: "200px 1fr 300px" }}
        gap={{ xs: 2, md: 4 }}
      >
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.iWouldLikeTo")}
          </Typography>
          <ButtonGroupToggle
            options={TypesProductConstant}
            labelAsI18n={true}
            value={MarketplaceStore.form.type}
            onChange={(value: string) => onInputChange(value, "type")}
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.productType")}{" "}
          </Typography>
          <SearchWithPopover
            options={[...products]}
            optionKeyLabel={i18n.language === "th" ? "nameTh" : "nameEn"}
            optionKeyImage="storage"
            debounce={500}
            selected={MarketplaceStore.form.product}
            onInputChange={(value: string) => fetchProductBySearch(value)}
            onSelected={(value: any) => {
              onInputChange(value, "product");
            }}
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.province")}
          </Typography>
          <SelectionDropdown
            options={[...MarketplaceStore.provinces]}
            labelKey={i18n.language === "th" ? "nameTh" : "nameEn"}
            valueKey="id"
            isError={MarketplaceStore.formIsInvalid.province}
            selected={MarketplaceStore.form.province}
            onSelected={(value: any) => onInputChange(value, "province")}
          />
        </Box>
      </Box>
      {/* /.Type, ProductType, Province */}

      {/* Volume, Unit, Price, Price Unit */}
      <Box
        display={"grid"}
        gridTemplateColumns={{ xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
        gap={{ xs: 2, md: 4 }}
        marginTop={2}
      >
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.volume")}
          </Typography>
          <Input
            value={MarketplaceStore.form.volume}
            placeholder="10"
            error={MarketplaceStore.formIsInvalid.volume}
            onChange={(e) =>
              onInputChange(NumberFormatUtil(e.target.value), "volume")
            }
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.unit")}
          </Typography>
          <SelectionDropdown
            options={[...MarketplaceStore.units]}
            selected={MarketplaceStore.form.unit}
            labelKey={i18n.language === "th" ? "nameTh" : "nameEn"}
            valueKey="id"
            isError={MarketplaceStore.formIsInvalid.unit}
            onSelected={(value: any) => onInputChange(value, "unit")}
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.price")}
          </Typography>
          <Input
            value={MarketplaceStore.form.price}
            placeholder="10"
            error={MarketplaceStore.formIsInvalid.price}
            onChange={(e) =>
              onInputChange(NumberFormatUtil(e.target.value), "price")
            }
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.unit")}
          </Typography>
          <SelectionDropdown
            options={[...MarketplaceStore.units]}
            selected={MarketplaceStore.form.priceUnit}
            labelKey={i18n.language === "th" ? "nameTh" : "nameEn"}
            valueKey="id"
            isError={MarketplaceStore.formIsInvalid.priceUnit}
            onSelected={(value: any) => onInputChange(value, "priceUnit")}
          />
        </Box>
      </Box>
      {/* /.Volume, Unit, Price, Price Unit */}

      {/* Title */}
      <Box marginTop={2}>
        <Typography variant="label" color={StyleVariables["color-white"]}>
          {t("marketplace.form.topic")}
        </Typography>
        <Input
          value={MarketplaceStore.form.title}
          placeholder={t("marketplace.form.topicPlaceholder")}
          error={MarketplaceStore.formIsInvalid.title}
          onChange={(e) => onInputChange(e.target.value, "title")}
        />
      </Box>
      {/* /.Title */}

      {/* Line Id, Phone Number 1, Phone Number 2 */}
      <Box
        display={"grid"}
        gridTemplateColumns={{ xs: "1fr 1fr", md: "1fr 1fr 1fr" }}
        gap={{ xs: 2, md: 4 }}
        marginTop={2}
      >
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.lineId")}
          </Typography>
          <Input
            value={MarketplaceStore.form.lineId}
            placeholder={t("marketplace.form.lineId")}
            onChange={(e) => onInputChange(e.target.value, "lineId")}
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.phoneNumber")} 1
          </Typography>
          <Input
            value={MarketplaceStore.form.phoneNumber1}
            placeholder={`${t("marketplace.form.phoneNumber")} 1`}
            onChange={(e) =>
              onInputChange(
                PhoneNumberFormatUtil(e.target.value),
                "phoneNumber1"
              )
            }
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("marketplace.form.phoneNumber")} 2
          </Typography>
          <Input
            value={MarketplaceStore.form.phoneNumber2}
            placeholder={`${t("marketplace.form.phoneNumber")} 2`}
            onChange={(e) =>
              onInputChange(
                PhoneNumberFormatUtil(e.target.value),
                "phoneNumber2"
              )
            }
          />
        </Box>
      </Box>
      {/* /.Line Id, Phone Number 1, Phone Number 2 */}

      {/* Description */}
      <Box marginTop={2}>
        <Typography variant="label" color={StyleVariables["color-white"]}>
          {t("marketplace.form.description")}
        </Typography>
        <textarea
          rows={6}
          placeholder={t("marketplace.form.descriptionPlaceholder")}
          value={MarketplaceStore.form.description}
          onChange={(e) => onInputChange(e.target.value, "description")}
        />
      </Box>
      {/* /.Description */}

      {/* Upload Image */}
      <Box marginTop={3}>
        <Typography variant="label" color={StyleVariables["color-white"]}>
          {t("marketplace.form.uploadImage")}
        </Typography>
        <UploadMultipleImageInput
          maximum={7}
          defaultImages={MarketplaceStore.postImages}
          isUseStorage={true}
          onChange={(files: File[]) => MarketplaceStore.onSetFiles([...files])}
          onDeleteDefaultImage={(image: any) => handleDeleteImage(image)}
        />
      </Box>
      {/* /.Upload Image */}

      <Box marginTop={3} display={"flex"} justifyContent={"flex-end"}>
        <Button variant="contained-warning" onClick={onSubmit}>
          {t("common.ok")}
        </Button>
      </Box>
    </>
  );
});

export default Post;
