import React from "react";
import { useMetaTags } from "react-metatags-hook";
import GeneralLayout from "../layouts/General";
import { Box, Container, Typography } from "@mui/material";
import StyleVariables from "../assets/styles/Variable.module.scss";
import ResetPasswordForm from "../components/forms/ResetPassword";
import { MetaTags as MetaTagsConstant } from "../constants/MetaTag";

type Props = {};

const ResetPassword = (props: Props) => {
  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  return (
    <GeneralLayout>
      <Box
        className="container-content"
        display={"flex"}
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Container>
          <Box marginX={"auto"} maxWidth={{ md: 600 }}>
            <Typography
              variant="h1"
              color={StyleVariables["color-primary"]}
              marginBottom={6}
              textAlign={"center"}
            >
              รีเซ็ตรหัสผ่าน
            </Typography>
            <Box>
              <ResetPasswordForm />
            </Box>
          </Box>
        </Container>
      </Box>
    </GeneralLayout>
  );
};

export default ResetPassword;
