import StyleVariables from "../assets/styles/Variable.module.scss";

const Input: any = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "16px !important",
        lineHeight: "24px !important",
        height: "42px !important",
        fontWeight: "300 !important",
        color: `${StyleVariables["color-black"]} !important`,
        borderRadius: "4px !important",
        border: `1px solid ${StyleVariables["color-primary"]} !important`,
        padding: `${Number(StyleVariables.spacing)}px ${
          Number(StyleVariables.spacing) * 2
        }px !important`,
        width: "100%",
        background: StyleVariables["color-white"],
        "&:before,&:after": {
          borderBottom: "0 !important",
        },
        "&.Mui-error": {
          border: `1px solid ${StyleVariables["color-error"]} !important`,
        },
        "&.Mui-disabled": {
          background: StyleVariables["color-disable"],
        },
      },
      input: {
        padding: "0 !important",
        "&.Mui-disabled": {
          WebkitTextFillColor: StyleVariables["color-black"],
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        "&::placeholder": {
          color: `${StyleVariables["color-placeholder"]} !important`,
          WebkitTextFillColor: `${StyleVariables["color-placeholder"]} !important`,
          opacity: 1,
        },
        "&:-ms-input-placeholder": {
          color: `${StyleVariables["color-placeholder"]} !important`,
          WebkitTextFillColor: `${StyleVariables["color-placeholder"]} !important`,
        },
        "&::-ms-input-placeholder": {
          color: `${StyleVariables["color-placeholder"]} !important`,
          WebkitTextFillColor: `${StyleVariables["color-placeholder"]} !important`,
        },
        "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
          {
            WebkitBoxShadow: `0 0 0 30px ${StyleVariables["color-white"]} inset !important;`,
          },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderStyle: "none",
        padding: 0,
      },
    },
  },
};

export default Input;
