import { Axios } from "../plugins";

const getUnits = async () => {
  try {
    const { data } = await Axios.get("/units");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

export { getUnits as GetUnits };
