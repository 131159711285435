import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import ImageIcon from "../ImageIcon";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  maximum: number;
  defaultImages?: any[];
  isUseStorage?: boolean;
  onChange: (files: File[]) => void;
  onDeleteDefaultImage?: (image: any) => void;
};

const UploadMultipleImage = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [files, setFiles] = useState<File[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setTotal(files.length + (props.defaultImages?.length || 0));
  }, [props.defaultImages, files]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempFiles: File[] = [...files];
    const tempImages: string[] = [...images];
    const arrNewFiles: any = Array.from(e.target.files as ArrayLike<File>);
    if (tempFiles.length < props.maximum) {
      const tempFileSlice = arrNewFiles.slice(
        0,
        props.maximum - tempFiles.length
      );
      const urlImageArr = tempFileSlice.map((file: any) =>
        URL.createObjectURL(file)
      );
      tempFiles.push(...tempFileSlice);
      tempImages.push(...urlImageArr);
      setFiles([...tempFiles]);
      setImages([...tempImages]);
      props.onChange([...tempFiles]);
    }
  };
  const handleDelete = (index: number) => {
    const tempFiles = [...files];
    const tempImages = [...images];
    tempFiles.splice(index, 1);
    tempImages.splice(index, 1);
    setFiles([...tempFiles]);
    setImages([...tempImages]);
  };
  const renderContainerImage = (
    onDelete: (value: any) => void,
    imageElement: JSX.Element
  ) => {
    return (
      <Box
        position={"relative"}
        minWidth={120}
        minHeight={120}
        width={120}
        height={120}
      >
        <Box
          position={"absolute"}
          borderRadius={"100%"}
          bgcolor={StyleVariables["color-background"]}
          width={25}
          height={25}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          right={-10}
          top={-10}
          className="cursor-pointer"
          onClick={onDelete}
        >
          <CloseIcon
            sx={{
              fontSize: 14,
              color: StyleVariables["color-neutralBlack"],
            }}
          />
        </Box>
        {imageElement}
      </Box>
    );
  };

  return (
    <Box display={"flex"} gap={3} flexWrap={"wrap"}>
      {props.defaultImages?.map((image, index) => {
        return (
          <Box key={index}>
            {renderContainerImage(
              () =>
                (props.onDeleteDefaultImage &&
                  props.onDeleteDefaultImage(image)) ||
                {},
              <img
                src={
                  props.isUseStorage
                    ? `${apiUrl}/storages/${image.storage?.id}`
                    : image
                }
                alt={`preview-${index}`}
                className="img-object-cover"
                loading="lazy"
              />
            )}
          </Box>
        );
      })}
      {images.map((image, index) => {
        return (
          <Box key={index}>
            {renderContainerImage(
              () => handleDelete(index),
              <img
                src={image}
                alt={`preview-${index}`}
                className="img-object-cover"
                loading="lazy"
              />
            )}
          </Box>
        );
      })}
      {total < 7 && (
        <Box
          component="label"
          className="upload-multiple-button"
          minWidth={120}
          minHeight={120}
          width={120}
          height={120}
        >
          <ImageIcon iconClassName="image-icon-gray plus" />
          <input
            type="file"
            hidden
            accept="image/*"
            multiple
            onChange={onFileChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default UploadMultipleImage;
