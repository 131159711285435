const Paper: any = {
  MuiPaper: {
    styleOverrides: {
      root: {
        "&.MuiMenu-paper": {
          ".MuiMenu-list": {
            ".MuiMenuItem-root": {
              minHeight: 0,
            },
          },
        },
      },
    },
  },
};

export default Paper;
