import "dayjs/locale/th";
import Dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

Dayjs.extend(buddhistEra);

export default class NewAdapter extends AdapterDayjs {
  constructor({ locale, formats, instance }) {
    super({
      locale,
      formats,
      instance,
    });
  }
  formatByString = (date, format) => {
    let newFormat =
      this.locale === "th" ? format.replace(/\bYYYY\b/g, "BBBB") : format;
    return this.dayjs(date).format(newFormat);
  };
}
