import React from "react";
import { Snackbar, CircularProgress, Typography, Box } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  isOpen: boolean;
  message?: string;
  onClose?: () => void;
};

const Processing = (props: Props) => {
  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <Snackbar
      open={props.isOpen}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={null}
      onClose={handleClose}
    >
      <Box
        bgcolor={StyleVariables["color-white"]}
        color={StyleVariables["color-neutral"]}
        borderRadius={"4px"}
        padding={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minWidth={120}
        boxShadow={"0px 10px 15px -3px rgba(0,0,0,0.1)"}
      >
        <CircularProgress
          size={14}
          style={{ color: StyleVariables["color-neutral"] }}
        />
        <Typography variant="body2" marginLeft={1}>
          {props.message || "Processing"}
        </Typography>
      </Box>
    </Snackbar>
  );
};

export default Processing;
