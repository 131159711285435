import React, { useState } from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import {
  SearchProductPlaceholderTh as SearchProductPlaceholderThConstant,
  SearchProductPlaceholderEn as SearchProductPlaceholderEnConstant,
} from "../../constants/Search";
import SearchWithAnimatePlaceholderInput from "./SearchWithAnimatePlaceholder";
import { Search as SearchProductService } from "../../services/Product";
import { useStores } from "../../stores";
import { Paths as PathsConstant } from "../../constants/Route";
import ReactGA from "react-ga4";

type Props = {
  isShowBackButton?: boolean;
  onClickBackButton?: () => void;
};

const SearchProduct = observer((props: Props) => {
  const navigate = useNavigate();
  const { SnackbarStore } = useStores();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const handleInputSearchChange = async (value: string) => {
    SnackbarStore.onOpenSnackbarProcessing("");
    const response = await SearchProductService(value);
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success)
      SnackbarStore.onOpenSnackbarError(
        response.message
          ? `${response.statusCode} ${response.message}`
          : "เกิดข้อผิดพลาดในการค้นหา กรุณาลองใหม่อีกครั้ง"
      );
    setSearchResults([...response.data]);
    ReactGA.event("search", { search_term: value });
  };

  return (
    <SearchWithAnimatePlaceholderInput
      options={[...searchResults]}
      optionKeyLabel="nameTh"
      optionKeyImage="storage"
      placeholderAnimateThList={SearchProductPlaceholderThConstant}
      placeholderAnimateEnList={SearchProductPlaceholderEnConstant}
      isShowBackButton={props.isShowBackButton}
      debounce={500}
      onClickBackButton={props.onClickBackButton}
      onInputChange={(value: string) => handleInputSearchChange(value)}
      onClickSearchButton={(value: string) => {
        ReactGA.event("search", { search_term: value });
        navigate(`${PathsConstant.product.search(`q=${value}`)}`);
      }}
      onSelected={(value: any) => {
        value.id && navigate(PathsConstant.product.price.today(value.id));
      }}
    />
  );
});

export default SearchProduct;
