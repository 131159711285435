import { Axios } from "../plugins";

const search = async (keyword: string) => {
  try {
    const { data } = await Axios.get("/products/search", {
      params: { keyword },
    });
    return {
      success: data,
      data: data?.data || [],
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const getProductById = async (id: string, params: any) => {
  try {
    const { data } = await Axios.get(`/products/${id}`, { params });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

const getProductGallery = async () => {
  try {
    const { data } = await Axios.get("/products/gallery");
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

export {
  search as Search,
  getProductById as GetProductById,
  getProductGallery as GetProductGallery,
};
