import React from "react";
import { Box } from "@mui/material";
import IconFacebook from "../assets/images/icons/IconFacebook.png";
import IconLine from "../assets/images/icons/IconLine.png";
import IconTwitter from "../assets/images/icons/IconTwitter.png";
import ReactGA from "react-ga4";

type Props = {
  facebookQuery: string;
  lineQuery: string;
  xQuery: string;
  iconSize?: number;
  contentType?: string;
  contentId?: string;
};

const SocialShare = (props: Props) => {
  const facebookShareUrl = process.env.REACT_APP_FACEBOOK_SHARE_URL;
  const lineShareUrl = process.env.REACT_APP_LINE_SHARE_URL;
  const xShareUrl = process.env.REACT_APP_X_SHARE_URL;

  const handleSharing = (key: string) => {
    switch (key) {
      case "facebook":
        window.open(`${facebookShareUrl}${props.facebookQuery}`);
        break;
      case "line":
        window.open(`${lineShareUrl}${props.lineQuery}`);
        break;
      case "x":
        window.open(`${xShareUrl}${props.xQuery}`);
        break;

      default:
        break;
    }
    if (props.contentType && props.contentId) {
      ReactGA.event("share", {
        method: key,
        content_type: props.contentType,
        item_id: props.contentId,
      });
    }
  };

  return (
    <Box display={"flex"} gap={1}>
      <img
        src={IconFacebook}
        alt="facebook-share"
        width={props.iconSize || 40}
        height={props.iconSize || 40}
        className="cursor-pointer"
        onClick={() => handleSharing("facebook")}
      />
      <img
        src={IconLine}
        alt="line-share"
        width={props.iconSize || 40}
        height={props.iconSize || 40}
        className="cursor-pointer"
        onClick={() => handleSharing("line")}
      />
      <img
        src={IconTwitter}
        alt="twitter-share"
        width={props.iconSize || 40}
        height={props.iconSize || 40}
        className="cursor-pointer"
        onClick={() => handleSharing("x")}
      />
    </Box>
  );
};

export default SocialShare;
