import React from "react";
import { Router as RouterModel } from "./models/Router";
import { Paths as PathsConstant } from "./constants/Route";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import SocialLogin from "./pages/SocialLogin";
import ResetPassword from "./pages/ResetPassword";
import MyProfile from "./pages/profiles/MyProfile";
import EditProfile from "./pages/profiles/Edit";
import MarketPlaceList from "./pages/marketplaces/List";
import MarketPlacePost from "./pages/marketplaces/Post";
import MarketplacePostDetail from "./pages/marketplaces/PostDetail";
import MarketplacePostEdit from "./pages/marketplaces/PostEdit";
import MarketplaceAnnouncerProfile from "./pages/marketplaces/AnnouncerProfile";
import ProductPriceToday from "./pages/products/PriceToday";
import ProductPricePast from "./pages/products/PricePast";
import ProductSearch from "./pages/products/Search";

const Routers: RouterModel[] = [
  {
    path: PathsConstant.notFound,
    element: <NotFound />,
    title: "Page Not Found",
  },
  {
    path: PathsConstant.home,
    element: <Home />,
    title: "Home",
  },
  {
    path: PathsConstant.aboutUs,
    element: <AboutUs />,
    title: "About Us",
  },
  {
    path: PathsConstant.contactUs,
    element: <ContactUs />,
    title: "Contact Us",
  },
  {
    path: PathsConstant.socialLogin,
    element: <SocialLogin />,
    title: "Social Login",
  },
  {
    path: PathsConstant.resetPassword,
    element: <ResetPassword />,
    title: "Reset Password",
  },
  {
    path: PathsConstant.profile.me,
    element: <MyProfile />,
    title: "My Profile",
    auth: true,
  },
  {
    path: PathsConstant.profile.edit,
    element: <EditProfile />,
    title: "Edit Profile",
    auth: true,
  },
  {
    path: PathsConstant.marketPlace.list,
    element: <MarketPlaceList />,
    title: "Marketplace List",
  },
  {
    path: PathsConstant.marketPlace.post,
    element: <MarketPlacePost />,
    title: "Marketplace Post",
    auth: true,
  },
  {
    path: PathsConstant.marketPlace.postDetail(":postId"),
    element: <MarketplacePostDetail />,
    title: "Marketplace Post Detail",
  },
  {
    path: PathsConstant.marketPlace.postEdit(":postId"),
    element: <MarketplacePostEdit />,
    title: "Marketplace Edit Post",
    auth: true,
  },
  {
    path: PathsConstant.marketPlace.announcerProfile(":announcerId"),
    element: <MarketplaceAnnouncerProfile />,
    title: "Marketplace Announcer Profile",
  },
  {
    path: PathsConstant.product.price.today(":productId"),
    element: <ProductPriceToday />,
    title: "Product Price Today",
  },
  {
    path: PathsConstant.product.price.past(":productId", ":date"),
    element: <ProductPricePast />,
    title: "Product Price Past",
  },
  {
    path: PathsConstant.product.search(""),
    element: <ProductSearch />,
    title: "Product Search",
  },
];

export default Routers;
