import { observable, makeAutoObservable, action } from "mobx";
import {
  FormPost as FormPostModel,
  FormPostIsInvalid as FormPostIsInvalidModel,
  CreatePost as CreatePostModel,
  Post as PostModel,
} from "../models/Marketplace";
import { TypesProduct as TypesProductConstant } from "../constants/Product";
import { GetProvinces as GetProvincesService } from "../services/Address";
import { GetUnits as GetUnitsService } from "../services/MasterData";
import { Province as ProvinceModel } from "../models/Address";
import { Unit as UnitModel } from "../models/Product";
import { PostStorage as PostStorageModel } from "../models/Marketplace";

export default class MarketplaceStore {
  private initialForm = {
    title: "",
    type: TypesProductConstant[0].value,
    product: {
      id: 0,
      nameTh: "",
      nameEn: "",
      description: "",
    },
    volume: "",
    price: "",
    unit: {
      id: 0,
      nameTh: "",
      nameEn: "",
    },
    priceUnit: {
      id: 0,
      nameTh: "",
      nameEn: "",
    },
    phoneNumber1: "",
    phoneNumber2: "",
    lineId: "",
    province: {
      id: 0,
      nameTh: "",
      nameEn: "",
    },
    description: "",
  };
  private initialFormIsInvalid = {
    title: false,
    type: false,
    product: false,
    volume: false,
    price: false,
    unit: false,
    priceUnit: false,
    province: false,
  };

  @observable form: FormPostModel = { ...this.initialForm };
  @observable formIsInvalid: FormPostIsInvalidModel = {
    ...this.initialFormIsInvalid,
  };
  @observable postImages: PostStorageModel[] = [];
  @observable postImagesDeleted: number[] = [];
  @observable files: File[] = [];
  @observable provinces: ProvinceModel[] = [];
  @observable units: UnitModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  onSetForm(value: FormPostModel) {
    this.form = { ...value };
  }
  @action
  onSetFormIsInvalid(value: FormPostIsInvalidModel) {
    this.formIsInvalid = { ...value };
  }
  @action
  onSetFiles(files: File[]) {
    this.files = [...files];
  }
  @action
  onClearStore() {
    this.form = { ...this.initialForm };
    this.formIsInvalid = { ...this.initialFormIsInvalid };
    this.files = [];
    this.postImages = [];
    this.postImagesDeleted = [];
  }
  @action
  getFormBody() {
    const arrPhoneNumber: string[] = [];
    this.form.phoneNumber1 && arrPhoneNumber.push(`${this.form.phoneNumber1}`);
    this.form.phoneNumber2 && arrPhoneNumber.push(`${this.form.phoneNumber2}`);
    const body: CreatePostModel = {
      title: this.form.title,
      type: this.form.type,
      productId: Number(this.form.product.id),
      volume: Number(this.form.volume),
      unit: this.form.unit?.nameTh,
      price: Number(this.form.price),
      priceUnit: this.form.priceUnit?.nameTh,
      provinceId: Number(this.form.province?.id),
      lineId: this.form.lineId,
      description: this.form.description,
      phoneNumbers: [...arrPhoneNumber],
    };
    return body;
  }
  @action
  async fetchProvinces() {
    const response = await GetProvincesService();
    if (response.success) this.onSetProvinces(response.data || []);
  }
  @action
  async fetchUnits() {
    const response = await GetUnitsService();
    if (response.success) this.onSetUnits(response.data || []);
  }
  onSetProvinces(provinces: ProvinceModel[]) {
    this.provinces = [...provinces];
  }
  onSetUnits(units: UnitModel[]) {
    this.units = [...units];
  }
  @action
  async onMappingPost(post: PostModel) {
    !this.provinces.length && (await this.fetchProvinces());
    !this.units.length && (await this.fetchUnits());
    const formObj: FormPostModel = { ...this.form };
    const findProvince = this.provinces?.find(
      (province) =>
        province.nameTh === post.province || province.nameEn === post.province
    );
    const findUnit = this.units?.find(
      (unit) => unit.nameTh === post.unit || unit.nameEn === post.unit
    );
    const findUnitPrice = this.units?.find(
      (unit) => unit.nameTh === post.priceUnit || unit.nameEn === post.priceUnit
    );
    formObj.type = post.type;
    formObj.product = post.product || this.initialForm.product;
    formObj.province = findProvince || this.initialForm.province;
    formObj.volume = post.volume;
    formObj.unit = findUnit || this.initialForm.unit;
    formObj.price = post.price;
    formObj.priceUnit = findUnitPrice || this.initialForm.priceUnit;
    formObj.title = post.title;
    formObj.lineId = post.lineId || "";
    formObj.phoneNumber1 =
      (post.phoneNumbers && post.phoneNumbers[0]?.phoneNumber) || "";
    formObj.phoneNumber2 =
      (post.phoneNumbers && post.phoneNumbers[1]?.phoneNumber) || "";
    formObj.description = post.description || "";
    this.onSetForm(formObj);
    this.onSetPostImages(
      (post.marketPostStorages?.length && [...post.marketPostStorages]) || []
    );
  }
  onSetPostImages(images: PostStorageModel[]) {
    this.postImages = [...images];
  }
  @action
  onDeletePostImages(id: number) {
    this.postImagesDeleted.push(id);
    this.postImages = this.postImages.filter(
      (postImage) => postImage.storage?.id !== id
    );
  }
}
