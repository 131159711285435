import StyleVariables from "../assets/styles/Variable.module.scss";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    link: true;
    label: true;
  }
}

const typography = {
  fontFamily: "Kanit",
  letterSpacing: 1,
  h1: {
    fontSize: "32px",
    lineHeight: "1.5",
    fontWeight: 500,
    "@media (max-width:768px)": {
      fontSize: "28px",
    },
  },
  h2: {
    fontSize: "29px",
    lineHeight: "1.5",
    fontWeight: 500,
    "@media (max-width:768px)": {
      fontSize: "25px",
    },
  },
  h3: {
    fontSize: "26px",
    lineHeight: "1.5",
    fontWeight: 500,
    "@media (max-width:768px)": {
      fontSize: "22px",
    },
  },
  h4: {
    fontSize: "24px",
    lineHeight: "1.5",
    fontWeight: 500,
    "@media (max-width:768px)": {
      fontSize: "19px",
    },
  },
  h5: {
    fontSize: "20px",
    lineHeight: "1.5",
    fontWeight: 500,
    "@media (max-width:768px)": {
      fontSize: "17px",
    },
  },
  h6: {
    fontSize: "18px",
    lineHeight: "1.5",
    fontWeight: 500,
    "@media (max-width:768px)": {
      fontSize: "15px",
    },
  },
  subtitle1: {
    fontSize: "20px",
    lineHeight: "1.5",
    fontWeight: 400,
    "@media (max-width:768px)": {
      fontSize: "18px",
    },
  },
  subtitle2: {
    fontSize: "20px",
    lineHeight: "1.5",
    fontWeight: 300,
    "@media (max-width:768px)": {
      fontSize: "18px",
    },
  },
  body1: {
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: 400,
    "@media (max-width:768px)": {
      fontSize: "14px",
    },
  },
  body2: {
    fontSize: "14px",
    lineHeight: "1.5",
    fontWeight: 400,
    "@media (max-width:768px)": {
      fontSize: "12px",
    },
  },
  button: {
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: 400,
    textTransform: "initial",
    "@media (max-width:768px)": {
      fontSize: "14px",
    },
  },
  caption: {
    fontSize: "14px",
    lineHeight: "1.5",
    fontWeight: 300,
    "@media (max-width:768px)": {
      fontSize: "12px",
    },
  },
  overline: {
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: 400,
    "@media (max-width:768px)": {
      fontSize: "14px",
    },
  },
  link: {
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: 400,
    "@media (max-width:768px)": {
      fontSize: "14px",
    },
  },
  label: {
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: 400,
    paddingBottom: Number(StyleVariables.spacing),
    color: StyleVariables["color-neutral"],
    display: "block",
    "@media (max-width:768px)": {
      fontSize: "14px",
    },
  },
};

const typographyComponent = {
  MuiTypography: {
    styleOverrides: {
      link: {
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
};

export { typography as Typography, typographyComponent as TypographyComponent };
