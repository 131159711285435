import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useNavigate } from "react-router";
import { Box, Typography, Button, Input } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import ImageIcon from "../ImageIcon";
import { UserCredential as UserCredentialModel } from "../../models/User";
import {
  ValidateFormatEmail as ValidateFormatEmailUtil,
  PhoneNumberFormat as PhoneNumberFormatUtil,
  NameFormat as NameFormatUtil,
} from "../../utils/InputFormat";
import {
  UploadFile as UploadFileService,
  UpdateProfile as UpdateProfileService,
} from "../../services/User";
import { Paths as PathsConstant } from "../../constants/Route";

type Props = {};

const Profile = observer((props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { UserStore, SnackbarStore } = useStores();
  const [image, setImage] = useState<string>("");
  const [profileImageFile, setProfileImageFile] = useState<any>(null);
  const [form, setForm] = useState<UserCredentialModel>({
    email: "",
    username: "",
  });
  const [formIsInvalid, setFormIsInvalid] = useState({
    email: false,
    username: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
  });

  useEffect(() => {
    setForm({ ...UserStore.user });
  }, [UserStore.user]);

  const onInputChange = (key: string, secondKey: string, value: string) => {
    const formObj: any = { ...form };
    const formIsInvalidObj: any = { ...formIsInvalid };
    if (secondKey) {
      formObj[key][secondKey] = value;
      formIsInvalidObj[secondKey] = false;
    } else {
      formObj[key] = value;
      formIsInvalidObj[key] = false;
    }
    if (key === "email")
      formIsInvalidObj.email = Boolean(!ValidateFormatEmailUtil(value));
    else if (secondKey === "phoneNumber")
      formObj[key][secondKey] = PhoneNumberFormatUtil(value);
    else if (secondKey === "firstName" || secondKey === "lastName")
      formObj[key][secondKey] = NameFormatUtil(value);
    setForm({ ...formObj });
    setFormIsInvalid({ ...formIsInvalidObj });
  };

  const onValidateForm = () => {
    const formIsInvalidObj = { ...formIsInvalid };
    formIsInvalidObj.email = Boolean(
      !form.email || !ValidateFormatEmailUtil(form.email)
    );
    formIsInvalidObj.username = Boolean(!form.username);
    formIsInvalidObj.firstName = Boolean(!form.user?.firstName);
    formIsInvalidObj.lastName = Boolean(!form.user?.lastName);
    formIsInvalidObj.phoneNumber = Boolean(!form.user?.phoneNumber);
    setFormIsInvalid({ ...formIsInvalidObj });
    return Object.values(formIsInvalidObj).every((v) => v === false);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = e.target.files;
    setProfileImageFile(files[0] || null);
    files[0] ? setImage(URL.createObjectURL(files[0])) : setImage("");
  };

  const uploadUserFile = async () => {
    const response = await UploadFileService(profileImageFile);
    if (!response.success) {
      SnackbarStore.onCloseSnackbarProcessing();
      SnackbarStore.onOpenSnackbarError(
        `${response.error?.statusCode} ${response.error?.message}` ||
          t("common.fileUploadFailAlert")
      );
      return false;
    }
    return true;
  };

  const updateProfile = async () => {
    const response = await UpdateProfileService({
      username: form.username,
      firstName: form.user?.firstName || "",
      lastName: form.user?.lastName || "",
      phoneNumber: form.user?.phoneNumber || "",
      description: form.user?.description || "",
    });
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        `${response.error?.statusCode} ${response.error?.message}` ||
          t("profile.updateFail")
      );
      return false;
    }
    SnackbarStore.onOpenSnackbarSuccess(t("profile.updateSuccess"));
    return true;
  };

  const handleUpdateProfile = async () => {
    const formIsValid: boolean = onValidateForm();
    if (!formIsValid) {
      SnackbarStore.onOpenSnackbarError(t("common.formIsInvalidAlert"));
      return;
    }
    SnackbarStore.onOpenSnackbarProcessing(t("profile.isUpdating"));
    if (profileImageFile) {
      const responseUploadFile = await uploadUserFile();
      if (!responseUploadFile) return;
    }
    const responseUpdateProfile = await updateProfile();
    responseUpdateProfile && navigate(PathsConstant.profile.me);
  };

  return (
    <Box>
      <Box
        display={"grid"}
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
        gap={2}
      >
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("profile.image")}
          </Typography>
          <Box display={"flex"} alignItems={"center"} gap={3}>
            {image || UserStore.user.user?.storage ? (
              <Box
                width={84}
                height={84}
                minWidth={84}
                minHeight={84}
                borderRadius={"100%"}
                overflow={"hidden"}
                margin={2}
              >
                <img
                  src={
                    image ||
                    `${apiUrl}/storages/${UserStore.user.user?.storage?.id}` ||
                    ""
                  }
                  alt={`profile-${UserStore.user.username}`}
                  className="img-object-cover"
                />
              </Box>
            ) : (
              <Box className="profile-img-avatar-default-edit" margin={2}>
                <ImageIcon iconClassName="image-icon-gray avatar" />
              </Box>
            )}
            <Box>
              <Button variant="outlined-upload" component="label">
                {t("common.selectImage")}
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={onFileChange}
                />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} gap={3} flexDirection={"column"}>
          <Box>
            <Typography variant="label" color={StyleVariables["color-white"]}>
              {t("profile.email")}
            </Typography>
            <Input
              placeholder={t("common.pleaseEnter")}
              value={form.email}
              error={formIsInvalid.email}
              onChange={(e) => onInputChange("email", "", e.target.value)}
            />
          </Box>
          <Box>
            <Typography variant="label" color={StyleVariables["color-white"]}>
              {t("profile.displayName")}
            </Typography>
            <Input
              placeholder={t("common.pleaseEnter")}
              value={form.username}
              error={formIsInvalid.username}
              onChange={(e) => onInputChange("username", "", e.target.value)}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
        gap={2}
        marginY={3}
      >
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("profile.firstName")}
          </Typography>
          <Input
            placeholder="โปรดระบุ"
            value={form.user?.firstName || ""}
            error={formIsInvalid.firstName}
            onChange={(e) => onInputChange("user", "firstName", e.target.value)}
          />
        </Box>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("profile.lastName")}
          </Typography>
          <Input
            placeholder="โปรดระบุ"
            value={form.user?.lastName || ""}
            error={formIsInvalid.lastName}
            onChange={(e) => onInputChange("user", "lastName", e.target.value)}
          />
        </Box>
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
        gap={2}
        marginY={3}
      >
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("profile.phoneNumber")}
          </Typography>
          <Input
            placeholder={t("common.pleaseEnter")}
            value={form.user?.phoneNumber || ""}
            error={formIsInvalid.phoneNumber}
            onChange={(e) =>
              onInputChange("user", "phoneNumber", e.target.value)
            }
          />
        </Box>
      </Box>
      <Box display={"grid"}>
        <Box>
          <Typography variant="label" color={StyleVariables["color-white"]}>
            {t("profile.aboutMe")}
          </Typography>
          <textarea
            rows={6}
            placeholder={t("profile.aboutMe")}
            value={form.user?.description || ""}
            maxLength={160}
            onChange={(e) =>
              onInputChange("user", "description", e.target.value)
            }
          />
          <Typography variant="body2" textAlign={"right"}>
            {form.user?.description?.length || 0} / 160
          </Typography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={{ xs: "center", md: "flex-end" }}
        marginTop={3}
      >
        <Button variant="contained-warning" onClick={handleUpdateProfile}>
          {t("common.saveEdit")}
        </Button>
      </Box>
    </Box>
  );
});

export default Profile;
