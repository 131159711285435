import React from "react";
import { Box, Typography } from "@mui/material";
import { ColorPatterns as ColorPatternsConstant } from "../../constants/Product";
import StyleVariables from "../../assets/styles/Variable.module.scss";

type Props = {
  subProducts: any[];
};

const SubProductPrice = (props: Props) => {
  return (
    <Box>
      {props.subProducts.map((subProduct, index) => {
        return (
          <Box
            key={index}
            display={"flex"}
            alignItems={"center"}
            color={StyleVariables["color-black"]}
            borderBottom={
              index < props.subProducts.length - 1
                ? `1px solid ${StyleVariables["color-black"]}`
                : ""
            }
            paddingY={2}
          >
            <Box
              width={20}
              height={20}
              minWidth={20}
              minHeight={20}
              borderRadius={"100%"}
              bgcolor={ColorPatternsConstant[index]}
            />
            <Typography variant="body1" marginLeft={2}>
              {subProduct.nameTh}
            </Typography>
            <Box
              display={{ xs: "block", md: "flex" }}
              alignItems={"center"}
              marginLeft={"auto"}
              textAlign={"right"}
              gap={3}
            >
              <Typography variant="h4">
                {Number(subProduct.averagePrice) % 1 !== 0
                  ? Number(subProduct.averagePrice).toFixed(2)
                  : subProduct.averagePrice}
              </Typography>
              <Typography
                variant="body2"
                color={StyleVariables["color-neutral"]}
              >
                {subProduct.unit}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SubProductPrice;
