import CryptoJS from "crypto-js";

const aesEncryption = (dataString: string) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY || "");
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV || "");
  const encrypted = CryptoJS.AES.encrypt(dataString, key, { iv });
  const stringEncrypted = encrypted.toString();
  return stringEncrypted;
};

const aesDecryption = (encryptedCode: string) => {
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY || "");
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV || "");
  const bytes = CryptoJS.AES.decrypt(encryptedCode, key, { iv });
  let decryptedData = "";
  try {
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  }
  return decryptedData;
};

export { aesEncryption as AesEncryption, aesDecryption as AesDecryption };
