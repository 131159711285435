import React from "react";
import TransparentImage from "../assets/images/transparent.png";
import "../assets/styles/ImageIcon.scss";

type Props = {
  iconClassName: string;
};

const ImageIcon = (props: Props) => {
  return (
    <img
      src={TransparentImage}
      width="1"
      height="1"
      className={`image-icon ${props.iconClassName}`}
      alt="icon"
    />
  );
};

export default ImageIcon;
