import { Paths as PathsConstant } from "./Route";

const mobileGuestMenu = [
  {
    label: "marketplace.title",
    icon: "arrow-right",
    path: PathsConstant.marketPlace.list,
  },
];
const mobileUserMenu = [
  {
    label: "marketplace.title",
    icon: "arrow-right",
    path: PathsConstant.marketPlace.list,
  },
  {
    label: "marketplace.post",
    icon: "arrow-right",
    path: PathsConstant.marketPlace.post,
  },
  {
    label: "profile.title",
    icon: "arrow-right",
    path: PathsConstant.profile.me,
  },
];

export { mobileGuestMenu as MobileGuestMenu, mobileUserMenu as MobileUserMenu };
