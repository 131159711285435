import React from "react";
import { Navigate } from "react-router-dom";
import useSSR from "use-ssr";
import { Paths as PathsConstant } from "../constants/Route";

type Props = {
  children: JSX.Element;
};

const AuthGuard = (props: Props) => {
  const { isBrowser } = useSSR();
  return isBrowser && localStorage.accessToken ? (
    props.children
  ) : (
    <Navigate to={PathsConstant.home} />
  );
};

export default AuthGuard;
