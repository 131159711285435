import React, { useState, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { useMetaTags } from "react-metatags-hook";
import useSSR from "use-ssr";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Typography, Box, Container } from "@mui/material";
import GeneralLayout from "../../layouts/General";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { TypesProduct as TypesProductConstant } from "../../constants/Product";
import SearchInput from "../../components/inputs/Search";
import ButtonGroupToggle from "../../components/toggles/ButtonGroup";
import PostTable from "../../components/tables/Post";
import {
  GetPostsQuery as GetPostsQueryModel,
  Post as PostModel,
} from "../../models/Marketplace";
import { GetPosts as GetPostsService } from "../../services/Marketplace";
import GoogleAdsense from "../../components/GoogleAdsense";

type Props = {};

const List = observer((props: Props) => {
  const slotGoogleAdsenseDesktop =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_MARKETPLACE_DESKTOP;
  const slotGoogleAdsenseMobile =
    process.env.REACT_APP_GOOGLE_ADSENSE_SLOT_MARKETPLACE_MOBILE;
  const { t } = useTranslation();
  const { SnackbarStore } = useStores();
  const { isBrowser } = useSSR();
  const [filter, setFilter] = useState<GetPostsQueryModel>({
    sortBy: "createdAt",
    sortOrder: "DESC",
    type: TypesProductConstant[0].value,
    limit: 10,
    skip: 0,
    keyword: "",
  });
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [isEndOffPostsList, setIsEndOffPostsList] = useState<boolean>(false);
  const [windowPosition, setWindowPosition] = useState<number>(
    isBrowser ? window.scrollY : 0
  );

  useMetaTags(
    {
      title: "ตลาดนัดเกษตรไพรช์",
      description: "ตลาดนัดเกษตรไพรช์",
      metas: [
        { property: "og:title", content: "ตลาดนัดเกษตรไพรช์" },
        { property: "og:description", content: "ตลาดนัดเกษตรไพรช์" },
        { property: "og:image", content: "/favicon.ico" },
        { name: "twitter:creator", content: "ตลาดนัดเกษตรไพรช์" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: "ตลาดนัดเกษตรไพรช์" },
        { name: "twitter:description", content: "ตลาดนัดเกษตรไพรช์" },
      ],
    },
    []
  );

  useEffect(() => {
    fetchPosts(filter, posts);
    isBrowser && window.addEventListener("scroll", debounced);
    return () => {
      window.removeEventListener("scroll", debounced);
    };
  }, []);

  const onFilterChange = (key: string, value: any) => {
    const filterObj: any = { ...filter };
    filterObj[key] = value;
    filterObj.skip = 0;
    setFilter({ ...filterObj });
    fetchPosts(filterObj, []);
  };

  const fetchPosts = async (query: any, currentPosts: PostModel[]) => {
    SnackbarStore.onOpenSnackbarProcessing(t("marketplace.postIsFetching"));
    const tempPosts: PostModel[] = [...currentPosts];
    const response = await GetPostsService(query);
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        (response.message && `${response.statusCode} ${response.message}`) ||
          t("marketplace.postFetchFail")
      );
      return;
    }
    tempPosts.push(...response.data);
    setPosts([...tempPosts]);
    setIsEndOffPostsList(!response.data?.length);
  };

  const debounced = useDebouncedCallback(() => {
    setWindowPosition(isBrowser ? window.scrollY : 0);
    isBrowser &&
      window.scrollY > windowPosition &&
      !SnackbarStore.isOpenSnackbarProcessing &&
      !isEndOffPostsList &&
      fetchPosts({ ...filter, skip: posts.length }, posts);
  }, 500);

  return (
    <GeneralLayout>
      <Box className="container-content bg-gradient-full">
        <Container>
          <Box paddingY={4}>
            <GoogleAdsense
              slotDesktop={slotGoogleAdsenseDesktop || ""}
              slotMobile={slotGoogleAdsenseMobile || ""}
            />
            <Typography
              variant="h4"
              color={StyleVariables["color-white"]}
              marginBottom={3}
            >
              {t("marketplace.title")}
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={{ xs: 3, md: 6 }}
              flexDirection={{ xs: "column", md: "row" }}
              marginBottom={2}
            >
              <ButtonGroupToggle
                options={TypesProductConstant}
                labelAsI18n={true}
                value={filter.type}
                onChange={(value: string) => onFilterChange("type", value)}
              />
              <SearchInput
                placeholder={t("marketplace.searchPlaceholder")}
                debounce={500}
                onChange={(value: string) => onFilterChange("keyword", value)}
              />
            </Box>
            <PostTable posts={posts} />
          </Box>
        </Container>
      </Box>
    </GeneralLayout>
  );
});

export default List;
