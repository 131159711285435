import { Axios } from "../plugins";

const getResources = async (params: any) => {
  try {
    const { data } = await Axios.get("/resources", { params: { ...params } });
    return {
      success: true,
      data: data?.data,
    };
  } catch (error: any) {
    return {
      success: false,
      statusCode: error.response?.data?.code,
      message: error.response?.data?.message,
    };
  }
};

export { getResources as GetResources };
