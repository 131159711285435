import React, { useState } from "react";
import { Input, InputAdornment } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import ImageIcon from "../ImageIcon";

type Props = {
  placeholder: string;
  debounce?: number;
  onChange: (value: string) => void;
};

const Search = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = useDebouncedCallback((value: string) => {
    props.onChange(value);
  }, props.debounce || 0);

  return (
    <Input
      placeholder={props.placeholder}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
        handleInputChange(e.target.value);
      }}
      startAdornment={
        <InputAdornment position="start">
          <ImageIcon iconClassName="image-icon-green search" />
        </InputAdornment>
      }
    />
  );
};

export default Search;
