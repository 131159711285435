import React, { useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMetaTags } from "react-metatags-hook";
import { useStores } from "../stores";
import GeneralLayout from "../layouts/General";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Paths as PathsConstant } from "../constants/Route";
import { MetaTags as MetaTagsConstant } from "../constants/MetaTag";
import {
  LoginWithOneTimeToken as LoginWithOneTimeTokenService,
  GetMe as GetMeService,
} from "../services/User";
import StyleVariables from "../assets/styles/Variable.module.scss";
import ReactGA from "react-ga4";

type Props = {};

const SocialLogin = (props: Props) => {
  const navigate = useNavigate();
  const { SnackbarStore, UserStore } = useStores();
  const { t } = useTranslation();
  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  useEffect(() => {
    const autoLoginWithOneTimeToken = async () => {
      const token = query.get("token");
      if (token) {
        const response = await LoginWithOneTimeTokenService(token);
        if (response.success) {
          const responseMe = await GetMeService();
          if (responseMe.success) {
            UserStore.onSetIsLoggedIn(true);
            UserStore.onSetUser(responseMe.data || { username: "", email: "" });
            SnackbarStore.onOpenSnackbarSuccess(t("authentication.success"));
            ReactGA.event("login", { method: "Facebook" });
            navigate(PathsConstant.home);
          } else {
            SnackbarStore.onOpenSnackbarError(
              t("authentication.signInWithFacebookFail")
            );
          }
        } else {
          SnackbarStore.onOpenSnackbarError(
            t("authentication.signInWithFacebookFail")
          );
        }
      }
      navigate(PathsConstant.home);
    };

    autoLoginWithOneTimeToken();
  }, []);

  return (
    <GeneralLayout>
      <Box
        className="bg-gradient-full"
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        color={StyleVariables["color-white"]}
      >
        <Box marginBottom={10} textAlign={"center"}>
          <CircularProgress
            size={40}
            style={{ color: StyleVariables["color-white"] }}
          />
          <Box marginTop={2}>
            <Typography variant="h4">
              {t("common.pleaseWaitAMoment")}
            </Typography>
            <Typography variant="h4">
              {t("authentication.isLoggingIn")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </GeneralLayout>
  );
};

export default SocialLogin;
