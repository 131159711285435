import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { useMetaTags } from "react-metatags-hook";
import useSSR from "use-ssr";
import { observer } from "mobx-react";
import GeneralLayout from "../../layouts/General";
import { Box, Container, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import { TypesProduct as TypesProductConstant } from "../../constants/Product";
import { MetaTags as MetaTagsConstant } from "../../constants/MetaTag";
import { useStores } from "../../stores";
import ButtonGroupToggle from "../../components/toggles/ButtonGroup";
import UserInformation from "../../components/UserInformation";
import PostTable from "../../components/tables/Post";
import { GetPostsByUserId as GetPostsByUserIdService } from "../../services/Marketplace";
import {
  Post as PostModel,
  GetPostsQuery as GetPostsQueryModel,
} from "../../models/Marketplace";
import { AesDecryption as AesDecryptionUtil } from "../../utils/Crypto";

type Props = {};

const AnnouncerProfile = observer((props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const params = useParams();
  const { isBrowser } = useSSR();
  const { SnackbarStore } = useStores();
  const typeOptions = [
    { label: "product.type.all", value: "all" },
    ...TypesProductConstant,
  ];
  const [filter, setFilter] = useState<GetPostsQueryModel>({
    sortBy: "createdAt",
    sortOrder: "DESC",
    type: typeOptions[0].value,
    limit: 10,
    skip: 0,
    keyword: "",
  });
  const [posts, setPosts] = useState<PostModel[]>([]);
  const [isEndOffPostsList, setIsEndOffPostsList] = useState<boolean>(false);
  const [windowPosition, setWindowPosition] = useState<number>(
    isBrowser ? window.scrollY : 0
  );

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  useEffect(() => {
    fetchPostsByUserId(filter, posts);
    isBrowser && window.addEventListener("scroll", debounced);
    return () => {
      window.removeEventListener("scroll", debounced);
    };
  }, []);

  const fetchPostsByUserId = async (
    query: GetPostsQueryModel,
    currentPosts: PostModel[]
  ) => {
    SnackbarStore.onOpenSnackbarProcessing(t("marketplace.postIsFetching"));
    const { announcerId } = params;
    const { type, ...queryDeconstruct } = query;
    const tempPosts: PostModel[] = [...currentPosts];
    const announcerIdDecrypted = AesDecryptionUtil(announcerId || "");
    const response = await GetPostsByUserIdService(announcerIdDecrypted, {
      ...queryDeconstruct,
      ...(query.type !== "all" && { type }),
    });
    SnackbarStore.onCloseSnackbarProcessing();
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        response.message
          ? `${response.statusCode} ${response.message}`
          : t("marketplace.postFetchFail")
      );
      return;
    }
    tempPosts.push(...response.data);
    setPosts([...tempPosts]);
    setIsEndOffPostsList(!response.data?.length);
  };

  const onFilterChange = (key: string, value: any) => {
    const filterObj: any = { ...filter };
    filterObj[key] = value;
    filterObj.skip = 0;
    setFilter({ ...filterObj });
    fetchPostsByUserId(filterObj, []);
  };

  const debounced = useDebouncedCallback(() => {
    setWindowPosition(isBrowser ? window.scrollY : 0);
    isBrowser &&
      window.scrollY > windowPosition &&
      !SnackbarStore.isOpenSnackbarProcessing &&
      !isEndOffPostsList &&
      fetchPostsByUserId(
        {
          ...filter,
          skip: posts.length,
        },
        posts
      );
  }, 500);

  return (
    <GeneralLayout>
      <Box className="bg-gradient-full" flex={1}>
        <UserInformation
          email={
            (posts[0]?.user?.userCredentials &&
              posts[0]?.user?.userCredentials[0]?.email) ||
            "-"
          }
          username={
            (posts[0]?.user?.userCredentials &&
              posts[0]?.user?.userCredentials[0]?.username) ||
            "-"
          }
          description={posts[0]?.user?.description || "-"}
          imageUrl={
            posts[0]?.user?.storage?.id
              ? `${apiUrl}/storages/${posts[0]?.user?.storage?.id}`
              : ""
          }
          phoneNumber={posts[0]?.user?.phoneNumber || ""}
        />
        <Box className="container-content">
          <Container>
            <Box paddingY={4}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Typography variant="h4" color={StyleVariables["color-white"]}>
                  {t("marketplace.title")}
                </Typography>
                <Box marginLeft={{ xs: 0, md: "auto" }}>
                  <ButtonGroupToggle
                    options={typeOptions}
                    labelAsI18n={true}
                    value={filter.type}
                    onChange={(value: string) => onFilterChange("type", value)}
                  />
                </Box>
              </Box>
              <Box marginTop={2}>
                <PostTable posts={[...posts]} />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </GeneralLayout>
  );
});

export default AnnouncerProfile;
