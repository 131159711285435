import { createContext, useContext } from "react";
import SnackbarStore from "./Snackbar";
import UserStore from "./User";
import MarketplaceStore from "./Marketplace";

const stores = {
  UserStore: new UserStore(),
  SnackbarStore: new SnackbarStore(),
  MarketplaceStore: new MarketplaceStore(),
};

const storeContext = createContext(stores);
export const useStores = () => useContext(storeContext);

export default stores;
