import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMetaTags } from "react-metatags-hook";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import GeneralLayout from "../../layouts/General";
import { Container, Box, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import PostForm from "../../components/forms/Post";
import { CreatePost as CreatePostModel } from "../../models/Marketplace";
import {
  CreatePost as CreatePostService,
  UploadImagePost as UploadImagePostService,
} from "../../services/Marketplace";
import { Paths as PathsConstant } from "../../constants/Route";
import { MetaTags as MetaTagsConstant } from "../../constants/MetaTag";
import ReactGA from "react-ga4";

type Props = {};

const Post = observer((props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { MarketplaceStore, SnackbarStore } = useStores();

  useMetaTags(
    {
      title: MetaTagsConstant.title,
      description: MetaTagsConstant.description,
      metas: [
        { property: "og:title", content: MetaTagsConstant.title },
        { property: "og:description", content: MetaTagsConstant.description },
        {
          property: "og:image",
          content: "/favicon.ico",
        },
        {
          name: "twitter:creator",
          content: MetaTagsConstant.title,
        },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: MetaTagsConstant.title },
        {
          name: "twitter:description",
          content: MetaTagsConstant.description,
        },
      ],
    },
    []
  );

  useEffect(() => {
    return () => {
      MarketplaceStore.onClearStore();
    };
  }, []);

  const onCreatePost = async () => {
    SnackbarStore.onOpenSnackbarProcessing(t("marketplace.postIsCreating"));
    const body: CreatePostModel = MarketplaceStore.getFormBody();
    const response = await CreatePostService(body);
    if (!response.success) {
      SnackbarStore.onOpenSnackbarError(
        response.message
          ? `${response.statusCode} ${response.message}`
          : t("marketplace.createPostFail")
      );
      return;
    }
    if (MarketplaceStore.files.length) {
      for (const file of MarketplaceStore.files) {
        await UploadImagePostService(response.data.id, file);
      }
    }
    SnackbarStore.onCloseSnackbarProcessing();
    ReactGA.event("select_content", {
      content_type: `post_${body.type}_product`,
      content_id: body.productId,
    });
    navigate(PathsConstant.marketPlace.list);
  };

  return (
    <GeneralLayout>
      <Box className="container-content bg-gradient-full">
        <Container>
          <Box paddingY={4}>
            <Typography
              variant="h4"
              color={StyleVariables["color-white"]}
              marginBottom={3}
              textAlign={"center"}
            >
              {t("marketplace.form.title")}
            </Typography>
            <PostForm onSubmit={onCreatePost} />
          </Box>
        </Container>
      </Box>
    </GeneralLayout>
  );
});

export default Post;
