import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Hidden, Typography } from "@mui/material";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import LogoGray from "../../assets/images/LogoGray.png";
import { TypesProduct as TypesProductConstant } from "../../constants/Product";
import { Paths as PathsConstant } from "../../constants/Route";
import {
  DateFromNow as DateFromNowUtil,
  DateBuddhistEra as DateBuddhistEraUtil,
} from "../../utils/DateFormat";
import ImageIcon from "../ImageIcon";
import { Post as PostModel } from "../../models/Marketplace";

type Props = {
  posts: any[];
  withActions?: boolean;
  onEdit?: (post: PostModel) => void;
  onDelete?: (post: PostModel) => void;
};

const Post = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getProductTypeName = (value: string) => {
    const findProductTypeByValue = TypesProductConstant.find(
      (product) => product.value === `${value}`
    );
    return (findProductTypeByValue && t(findProductTypeByValue?.label)) || "";
  };

  return (
    <Box className="table-post">
      <Box
        className="table-post-head"
        display={"grid"}
        gridTemplateColumns={"55% 12% 15% 18%"}
        padding={2}
        color={StyleVariables["color-primary"]}
        bgcolor={StyleVariables["color-secondary"]}
      >
        <Typography variant="body1">{t("marketplace.table.list")}</Typography>
        <Hidden mdDown>
          <Typography variant="body1" textAlign={"center"}>
            {t("marketplace.table.volume")}
          </Typography>
          <Typography variant="body1" textAlign={"center"}>
            {t("marketplace.table.date")}
          </Typography>
          <Typography variant="body1" textAlign={"center"}>
            {!props.withActions
              ? t("marketplace.table.announcer")
              : t("marketplace.table.managePost")}
          </Typography>
        </Hidden>
      </Box>
      {props.posts.map((post, index) => {
        return (
          <Box
            key={index}
            className="table-post-body table-post-row cursor-pointer"
            display={"grid"}
            gridTemplateColumns={{
              xs: "33.33% 33.33% 33.33%",
              md: "55% 12% 15% 18%",
            }}
            gridTemplateRows={{ xs: "1fr auto", md: "1fr" }}
            rowGap={{ xs: 2, md: 0 }}
            alignItems={"center"}
            padding={{ xs: 1, md: 2 }}
            bgcolor={StyleVariables["color-white"]}
            borderTop={`1px solid ${StyleVariables["color-neutralLight"]}`}
            onClick={() =>
              navigate(PathsConstant.marketPlace.postDetail(post.id))
            }
          >
            {/* Image, Name, Description */}
            <Box
              display={"flex"}
              alignItems={"center"}
              borderRight={{
                md: `1px solid ${StyleVariables["color-neutralLight"]}`,
              }}
              gridColumn={{ xs: "span 3", md: "unset" }}
            >
              <Box minWidth={70} minHeight={70} width={70} height={70}>
                <img
                  src={
                    (post.marketPostStorages &&
                      post.marketPostStorages[0] &&
                      `${apiUrl}/storages/${post.marketPostStorages[0]?.storage?.id}`) ||
                    LogoGray
                  }
                  alt={post.name}
                  className="img-object-cover"
                  loading="lazy"
                />
              </Box>
              <Box marginX={2} className="full-width">
                <Box
                  display={"grid"}
                  gridTemplateColumns={"1fr 11fr"}
                  gap={4}
                  marginBottom={2}
                >
                  <Typography
                    variant="body1"
                    color={StyleVariables["color-warning"]}
                    className="text-underline"
                  >
                    {getProductTypeName(post.type)}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={StyleVariables["color-primary"]}
                    className="text-underline"
                  >
                    {post.title}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  color={StyleVariables["color-black"]}
                  className="text-2-ellipse"
                >
                  {post.description}
                </Typography>
              </Box>
            </Box>
            {/* /.Image, Name, Description */}

            {/* Quantity */}
            <Box
              borderRight={`1px solid ${StyleVariables["color-neutralLight"]}`}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              padding={1}
            >
              <Typography
                variant="body1"
                textAlign={"center"}
                color={StyleVariables["color-primary"]}
              >
                {(post.volume >= 0 && post.volume) || "-"}
              </Typography>
              <Typography
                variant="body1"
                textAlign={"center"}
                color={StyleVariables["color-neutral"]}
              >
                {post.unit}
              </Typography>
            </Box>
            {/* /.Quantity */}

            {/* Created Date */}
            <Box
              borderRight={`1px solid ${StyleVariables["color-neutralLight"]}`}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              padding={1}
            >
              <Typography
                variant="body1"
                textAlign={"center"}
                color={StyleVariables["color-primary"]}
              >
                {DateFromNowUtil(post.createdAt)}
              </Typography>
              <Typography
                variant="body1"
                textAlign={"center"}
                color={StyleVariables["color-neutral"]}
              >
                {DateBuddhistEraUtil(post.createdAt)}
              </Typography>
            </Box>
            {/* /.Created Date */}

            {/* Announcer or Manage Post */}
            {!props.withActions ? (
              <Box display={"flex"} alignItems={"center"} padding={1}>
                {post.user?.storage?.id ? (
                  <Box
                    minWidth={{ xs: 30, md: 40 }}
                    minHeight={{ xs: 30, md: 40 }}
                    width={{ xs: 30, md: 40 }}
                    height={{ xs: 30, md: 40 }}
                    borderRadius={"100%"}
                    overflow={"hidden"}
                  >
                    <img
                      src={`${apiUrl}/storages/${post.user?.storage?.id}`}
                      alt={
                        post.user?.userCredentials &&
                        post.user?.userCredentials[0]?.username
                      }
                      className="img-object-cover"
                    />
                  </Box>
                ) : (
                  <Box className="img-avartar-default">
                    <ImageIcon iconClassName="image-icon-gray avatar" />
                  </Box>
                )}
                <Typography
                  variant="body1"
                  color={StyleVariables["color-primary"]}
                  marginLeft={1}
                  className="text-2-ellipse"
                >
                  {(post.user?.userCredentials &&
                    post.user?.userCredentials[0]?.username) ||
                    "-"}
                </Typography>
              </Box>
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={2}
                padding={1}
              >
                <Typography
                  variant="body2"
                  color={StyleVariables["color-primary"]}
                  className="text-underline cursor-pointer"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.onEdit && props.onEdit(post);
                  }}
                >
                  {t("common.edit")}
                </Typography>
                <Typography
                  variant="body2"
                  color={StyleVariables["color-error"]}
                  className="text-underline cursor-pointer"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.onDelete && props.onDelete(post);
                  }}
                >
                  {t("common.delete")}
                </Typography>
              </Box>
            )}
            {/* /.Announcer or Manage Post */}
          </Box>
        );
      })}
      {!props.posts.length && (
        <Box
          bgcolor={StyleVariables["color-white"]}
          textAlign={"center"}
          padding={6}
          color={StyleVariables["color-neutral"]}
        >
          <Typography variant="body1">
            {t("marketplace.table.noData")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Post;
