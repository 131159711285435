import React from "react";
import { useTranslation } from "react-i18next";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

type Props = {
  value: string;
  labelAsI18n?: boolean;
  options: { label: string; value: any }[];
  onChange: (value: string) => void;
};

const ButtonGroup = (props: Props) => {
  const { t } = useTranslation();
  const handleOnChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    props.onChange(newValue);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={props.value}
      exclusive
      onChange={handleOnChange}
    >
      {props.options.map((option, index) => {
        return (
          <ToggleButton value={option.value} key={index}>
            {props.labelAsI18n ? t(`${option.label}`) : option.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default ButtonGroup;
