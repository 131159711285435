import i18next from "i18next";
import dayjs, { Dayjs } from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import BuddhistEra from "dayjs/plugin/buddhistEra";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/th";

dayjs.extend(RelativeTime);
dayjs.extend(BuddhistEra);
dayjs.extend(utc);

const dateFromNow = (date: Dayjs | string) => {
  return dayjs.utc(date).locale(i18next.language).fromNow();
};
const dateBuddhistEra = (date: Dayjs | string) => {
  const dateString =
    i18next.language === "th"
      ? dayjs(date).locale("th").format("DD MMM BBBB")
      : dayjs(date).format("DD MMM YYYY");
  return dateString;
};
const fullDateBuddhistEra = (date: Dayjs | string) => {
  const dateString =
    i18next.language === "th"
      ? dayjs(date).locale("th").format("DD MMMM BBBB")
      : dayjs(date).format("DD MMMM YYYY");
  return dateString;
};
const validateRangeOfDate = (fDate: string, lDate: string) => {
  const startDateDiff = dayjs(fDate).diff(dayjs(lDate), "day");
  const endDateDiff = dayjs(lDate).diff(dayjs(fDate), "day");
  let isStartDateValid = true;
  let isEndDateValid = true;
  startDateDiff > 0 && (isStartDateValid = false);
  endDateDiff < 0 && (isEndDateValid = false);
  return { isStartDateValid, isEndDateValid };
};

export {
  dateFromNow as DateFromNow,
  dateBuddhistEra as DateBuddhistEra,
  fullDateBuddhistEra as FullDateBuddhistEra,
  validateRangeOfDate as ValidateRangeOfDate,
};
