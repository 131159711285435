import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Paths as PathsConstant } from "../constants/Route";

type Props = {};

const Footer = (props: Props) => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <Box className="container-content">
        <Container>
          <Stack
            direction={{ xs: "column", md: "row" }}
            textAlign={{ xs: "center", md: "left" }}
          >
            <Typography variant="body1">
              &copy; 2023 kasetprice.com All rights reserved
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              marginLeft={{ xs: 0, md: "auto" }}
              marginTop={{ xs: 1, md: 0 }}
            >
              <Link to={PathsConstant.aboutUs}>
                <Typography variant="body1">{t("aboutUs.title")}</Typography>
              </Link>
              <Link to={PathsConstant.contactUs}>
                <Typography variant="body1">{t("contactUs.title")}</Typography>
              </Link>
            </Box>
          </Stack>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
