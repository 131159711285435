import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FilterProduct as FilterProductModel } from "../../models/Product";
import { Resource as ResourceModel } from "../../models/Resource";
import { GetResources as GetResourcesService } from "../../services/Resource";
import { ValidateRangeOfDate as ValidateRangeOfDateUtil } from "../../utils/DateFormat";
import SelectionDropdown from "../../components/dropdowns/Selection";
import DatePickerInput from "../../components/inputs/DatePicker";
import ButtonGroupToggle from "../../components/toggles/ButtonGroup";

type Props = {
  productId: number | string;
  defaultFilter?: FilterProductModel;
  defaultStartDate?: string;
  defaultEndDate?: string;
  onFilterChange: (filter: FilterProductModel) => void;
  onDisplayTypeChange: (value: string) => void;
};

const Product = (props: Props) => {
  const typeOptions = [
    { label: "product.pastPrice.graph", value: "GRAPH" },
    { label: "product.pastPrice.table", value: "TABLE" },
  ];
  const defaultResource: ResourceModel = {
    id: -1,
    name: "แสดงค่าเฉลี่ย",
    serviceUrl: "",
    description: "",
  };
  const { t } = useTranslation();

  const [resources, setResources] = useState<ResourceModel[]>([
    { ...defaultResource },
  ]);
  const [filter, setFilter] = useState<FilterProductModel>({
    startDate: "",
    endDate: "",
    resource: resources[0],
  });
  const [filterIsInvalid, setFilterIsInvalid] = useState({
    startDate: false,
    endDate: false,
  });
  const [type, setType] = useState<string>(typeOptions[0].value);

  useEffect(() => {
    props.productId && fetchResources();
  }, [props.productId]);

  useEffect(() => {
    props.defaultFilter && setFilter({ ...props.defaultFilter });
  }, [props.defaultFilter]);

  useEffect(() => {
    const filterObj: any = { ...filter };
    filterObj.startDate = props.defaultStartDate || "";
    filterObj.endDate = props.defaultEndDate || "";
    setFilter({ ...filterObj });
  }, [props.defaultStartDate, props.defaultStartDate]);

  useEffect(() => {
    props.onDisplayTypeChange(type);
  }, [type]);

  const fetchResources = async () => {
    const response = await GetResourcesService({ productId: props.productId });
    response.success && setResources([defaultResource, ...response.data]);
  };

  const onFilterChange = (value: any, key: string) => {
    const filterObj: any = { ...filter };
    const filterIsInvalidObj: any = { ...filterIsInvalid };
    filterObj[key] = value;
    if (key === "startDate" || key === "endDate") {
      const { isStartDateValid, isEndDateValid } = ValidateRangeOfDateUtil(
        key === "startDate" ? value : filterObj.startDate,
        key === "endDate" ? value : filterObj.endDate
      );
      filterObj[key] = value ? dayjs(value).format("YYYY-MM-DD") : "";
      filterIsInvalidObj.startDate = !isStartDateValid;
      filterIsInvalidObj.endDate = !isEndDateValid;
    }
    const filterIsValid = Object.values(filterIsInvalidObj).every(
      (v) => v === false
    );
    setFilter({ ...filterObj });
    setFilterIsInvalid({ ...filterIsInvalidObj });
    filterIsValid &&
      props.onFilterChange({
        ...filterObj,
        ...(filterObj.resource.id > 0 && { resourceId: filterObj.resource.id }),
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <Typography variant="label">
          {t("product.pastPrice.startDate")}
        </Typography>
        <DatePickerInput
          dateSelected={(filter.startDate && dayjs(filter.startDate)) || ""}
          isError={filterIsInvalid.startDate}
          isDisabledFuture={true}
          onDateSelected={(date: Dayjs | null | string) =>
            onFilterChange(date, "startDate")
          }
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography variant="label">
          {t("product.pastPrice.endDate")}
        </Typography>
        <DatePickerInput
          dateSelected={(filter.endDate && dayjs(filter.endDate)) || ""}
          isError={filterIsInvalid.endDate}
          isDisabledFuture={true}
          onDateSelected={(date: Dayjs | null | string) =>
            onFilterChange(date, "endDate")
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="label">
          {t("product.pastPrice.selectSource")}
        </Typography>
        <SelectionDropdown
          options={resources}
          selected={filter.resource}
          labelKey={"name"}
          valueKey={"id"}
          onSelected={(value: any) => onFilterChange(value, "resource")}
        />
      </Grid>
      <Grid item marginLeft={{ xs: 0, md: "auto" }}>
        <Typography variant="label">
          {t("product.pastPrice.displayAs")}
        </Typography>
        <ButtonGroupToggle
          options={typeOptions}
          value={type}
          labelAsI18n={true}
          onChange={(value: string) => value && setType(value)}
        />
      </Grid>
    </Grid>
  );
};

export default Product;
