import React, { useState } from "react";
import "../../assets/styles/DatePicker.scss";
import { useDebouncedCallback } from "use-debounce";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/th";
import AdapterDayjs from "../../utils/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

type Props = {
  isError?: boolean;
  dateSelected: any;
  isDisabled?: boolean;
  isDisabledFuture?: boolean;
  onDateSelected: (date: Dayjs | null | string) => void;
};

const DatePicker = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onInputChange = useDebouncedCallback((date: any) => {
    const newDate =
      dayjs(props.dateSelected).year() !== dayjs(date).year()
        ? dayjs(date).add(-543, "years")
        : dayjs(date);
    props.onDateSelected(newDate);
  }, 500);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="th">
      <DesktopDatePicker
        className="desktop-date-picker"
        value={props.dateSelected || null}
        format="DD-MM-YYYY"
        disabled={props.isDisabled}
        disableFuture={props.isDisabledFuture}
        open={isOpen}
        onAccept={(date: Dayjs | null) => {
          props.onDateSelected((date && dayjs(date)) || "");
        }}
        onClose={() => setIsOpen(false)}
        slotProps={{
          textField: {
            error: props.isError,
            onChange: (date: any) => {
              onInputChange(date);
            },
            onClick: () => setIsOpen(true),
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
