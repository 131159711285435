import React, { useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import StyleVariables from "../../assets/styles/Variable.module.scss";
import ImageIcon from "../ImageIcon";

type Props = {
  options: any[];
  placeholder?: string;
  optionKeyLabel?: string;
  optionKeyImage?: string;
  debounce?: number;
  defaultValue?: any;
  selected: any;
  onSelected: (value: any) => void;
  onInputChange: (value: string) => void;
};

const SearchWithPopover = (props: Props) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const optionLabelKeyName = props.optionKeyLabel;
  const optionImageKeyName = props.optionKeyImage;
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = useDebouncedCallback((value) => {
    setInputValue(value);
    props.onInputChange(value);
  }, props.debounce || 0);

  const handleSelected = (value: any) => {
    props.onSelected(value);
  };

  return (
    <Box position={"relative"} width={"100%"}>
      <Autocomplete
        className="input-search-with-popover"
        clearIcon=""
        freeSolo
        disableClearable
        options={props.options}
        getOptionLabel={(option) =>
          props.optionKeyLabel ? option[props.optionKeyLabel] : option
        }
        onChange={(event, value) => handleSelected(value)}
        onInputChange={(event, value) => handleInputChange(value)}
        defaultValue={props.defaultValue}
        filterOptions={(options) =>
          options.filter(
            (option) =>
              option.nameEn.includes(inputValue) ||
              option.nameTh.includes(inputValue) ||
              (props.optionKeyLabel &&
                option[props.optionKeyLabel].includes(inputValue))
          )
        }
        value={props.selected}
        renderInput={(params) => (
          <TextField
            {...params}
            hiddenLabel
            placeholder={props.placeholder || "ค้นหา"}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start" className="start-adornment">
                  <ImageIcon iconClassName="image-icon-green search" />
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {optionImageKeyName && (
              <Box
                minWidth={50}
                maxWidth={50}
                width={50}
                height={50}
                borderRadius={"100%"}
                overflow={"hidden"}
                marginRight={2}
              >
                <img
                  loading="lazy"
                  className="img-object-cover"
                  src={
                    optionImageKeyName === "storage"
                      ? `${apiUrl}/storages/${option.storage?.id}`
                      : option[optionImageKeyName]
                  }
                  alt={optionLabelKeyName ? option[optionLabelKeyName] : option}
                />
              </Box>
            )}
            <Typography
              variant="body1"
              color={StyleVariables["color-primary"]}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
              textOverflow={"ellipsis"}
            >
              {optionLabelKeyName ? option[optionLabelKeyName] : option}
            </Typography>
          </Box>
        )}
      />
    </Box>
  );
};

export default SearchWithPopover;
