import React, { useEffect } from "react";
import { Hidden } from "@mui/material";

declare global {
  interface Window {
    adsbygoogle: unknown[];
  }
}

type Props = {
  slotDesktop: string;
  slotMobile: string;
};

const GoogleAdsense = (props: Props) => {
  const key = `ca-pub-${process.env.REACT_APP_GOOGLE_ADSENSE_KEY}`;
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsByGoogle = window.adsbygoogle;
        adsByGoogle.push({});
      } catch (e) {
        console.log("error push google adsense", e);
      }
    };
    let interval = setInterval(() => {
      if (window.adsbygoogle) {
        pushAd();
        clearInterval(interval);
      }
    }, 300);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <Hidden mdDown>
        <ins
          className="adsbygoogle"
          style={{
            display: "inline-block",
            width: "100%",
            maxWidth: "768px",
            height: "90px",
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            marginBottom: "8px",
          }}
          data-ad-client={key}
          data-ad-slot={props.slotDesktop}
        ></ins>
      </Hidden>
      <Hidden mdUp>
        <ins
          className="adsbygoogle"
          style={{
            display: "inline-block",
            width: "100%",
            maxWidth: "336px",
            height: "280px",
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            marginBottom: "8px",
          }}
          data-ad-client={key}
          data-ad-slot={props.slotMobile}
        ></ins>
      </Hidden>
    </>
  );
};

export default GoogleAdsense;
